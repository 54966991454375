import axiosClient from "./axiosClient";

const TimeZoneApi = {
    updateTimeZone: (params, accessToken) => {
        const url = "/experts/update/timezone";
        return axiosClient.post(url, params,{
            headers: {
                "Access-Token": accessToken
            },
            validateStatus (status) {
                return status;
            }
        });
    },
};

export default TimeZoneApi;