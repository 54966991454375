import React from 'react';
import "./index.css"

function Vietnamese() {
  return (
    <div className='parent'>
      <p className='title text-color-primary'><strong>CÁC CÂU HỎI THƯỜNG GẶP</strong></p>
      <p> </p>
      <p><strong>1. Vmood là gì?</strong></p>
      <p><strong>    </strong><span className='font-weight-text'>Là một phần mềm ứng dụng sức khỏe trên thiết bị điện tử nhằm hỗ trợ tự sàng lọc, kiểm soát và giới thiệu điều trị cho người trầm cảm tại Việt Nam, giúp họ vượt qua trầm cảm. Phần mềm gồm có các tài liệu hướng dẫn kỹ năng kiểm soát trầm cảm (tái thiết cuộc sống, luyện tập tư duy tích cực, giải quyết vấn đề và kỹ năng ngăn ngừa tái phát). Bên cạnh đó, bạn cũng có thể lựa chọn sự giúp đỡ từ các Bác sĩ tâm thần, chuyên gia tư vấn tâm lý gần nhất từ danh sách (dịch vụ trả phí), có thể nhắn tin, nói chuyện hoặc gặp mặt trực tiếp; hoặc chọn sự giúp đỡ từ cộng tác viên gần nhất (miễn phí). </span></p>
      <p><strong><strong>2. Vmood giúp được gì cho mỗi chúng ta?</strong></strong></p>
      <p><span className='font-weight-text'>Vmood có thể giúp bạn quản lý cảm xúc và suy nghĩ của mình, thông qua các công cụ và kỹ thuật dựa trên bằng chứng nhằm khuyến khích bạn tự vượt qua trầm cảm. Vmood không được thiết kế để hỗ trợ các cuộc khủng hoảng như: bị lạm dụng, tình trạng sức khỏe tâm thần nghiêm trọng có thể gây ra cảm giác muốn tự tử, tự làm hại bản thân và bất kỳ trường hợp cấp cứu y tế nào khác. Trong bất kỳ trường hợp nào trong số này và các trường hợp khác, bạn có thể liên hệ với Bác sĩ tâm thần để nhận được lời khuyên y tế hoặc lâm sàng.</span></p>
      <p><strong><strong>3. Vmood hoạt động như thế nào?</strong></strong></p>
      <p><span className='font-weight-text'>     Vmood là kết quả của sự nỗ lực đồng phát triển và thiết kế của nhóm các Giáo sư, Thạc sỹ, Bác sỹ và các chuyên gia, nhà phát triển và kết quả thử nghiệm lâm sàng ngẫu nhiên có đối chứng được đánh giá hiệu quả, Vmood ra đời với mong muốn có thể giúp chúng ta trang bị các kỹ năng phục hồi cảm xúc. Chúng tôi đã học được hai điều quan trọng.  Đầu tiên, quyền riêng tư là chìa khóa. Vì vậy, chúng tôi quyết định rằng tất cả các cuộc trò chuyện của Vmood sẽ được ẩn danh. Thứ hai, có những cảm xúc mà chúng ta không chia sẻ với bất kỳ ai khác và khi xuất hiện các triệu chứng trầm cảm và lo lắng, chúng ta thường ngại đi khám vì sợ sự kỳ thị, xa lánh, rồi có khi khám rồi nhưng lại sợ không đến để điều trị. Hầu hết, chúng ta chỉ muốn được tư vấn, nói chuyện riêng tư với Bác sĩ và chuyên gia mà không sợ bị người khác phán xét. Một nền tảng kết nối dựa trên Ứng dụng điện tử có thể tuyệt vời cho những cuộc trò chuyện riêng tư và điều này mang lại cho chúng ta sự tự tin để liên hệ với sự giúp đỡ từ bạn bè, gia đình hoặc Bác sĩ, chuyên gia tâm lý. Chúng tôi hiểu thời gian trị liệu với các liệu trình đôi khi chưa đủ, bạn cần tập luyện thêm các kỹ năng khác có sẵn trong Vmood. </span></p>
      <p><strong><strong>4. Sử dụng Vmood có miễn phí không?</strong></strong></p>
      <p><strong>5. Vmood được thiết kế cho ai?</strong></p>
      <p><strong>6. </strong><strong>Tính khoa học của các kỹ thuật áp dụng trong Vmood là gì?</strong></p>
      <p><strong>7. Đối tác hợp tác và những bên liên quan cùng phát triển phần mềm Vmood?</strong></p>
      <p><strong>8. </strong><strong>Làm sao để có thể đăng ký tài khoản người dùng trong hệ thống Vmood?</strong></p>
      <p><span className='font-weight-text'>Để đăng ký tài khoản người dùng trong hệ thống Vmood, bạn làm theo hướng dẫn sau.</span></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p><strong>Bước 1:</strong><span className='font-weight-text'> Nhấn vào "Đăng ký" trong trang đăng nhập.</span></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p><strong>Bước 2:</strong><span className='font-weight-text'> Đọc điều khoản sử dụng và chọn "Đồng ý với điều khoản sử dụng" nếu như bạn đồng ý các điều khoản để sử dụng dịch vụ hệ thống.</span></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p><strong>Bước 3:</strong><span className='font-weight-text'> Nhập số điện thoại của bạn và nhấn vào "Đăng ký".</span></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p><strong>Bước 4:</strong><span className='font-weight-text'> Hệ thống sẽ gửi 1 mã OTP đến số điện thoại của bạn, nhập đúng mã OTP và nhấn vào "Tiếp tục". (nếu không nhận được mã OTP, nhấn "Gửi lại mã" để hệ thống cung cấp lại mã OTP cho bạn)</span></p>
      <p><strong>Bước 5:</strong><span className='font-weight-text'> Nhập mật khẩu theo đúng nguyên tắc cho tài khoản của bạn và nhấn Tiếp tục.</span></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p><strong>Bước 6:</strong><span className='font-weight-text'> Bạn có thể nhập đầy đủ thông tin cá nhân và nhấn "Cập nhật" hoặc nhập thông tin cá nhân sau bằng cách nhấn "Để sau".</span></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p><strong>Bước 7:</strong><span className='font-weight-text'> Bạn đã đăng ký tài khoản người dùng thành công trong hệ thống Vmood. Giờ bạn đã có thể truy cập vào hệ thống bằng cách nhấn vào "Vào màn hình chính".</span></p>
    </div>
  );
}

export default Vietnamese;