import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {Button, Spinner} from 'react-bootstrap';
import avatarDefault from '../../assets/images/default_avatar.png';
import './profile.css';

function Profile(props) {
  const {t} = useTranslation();
  const {username, logoutLogic, avatar} = props;
  const [disableLogout, setDisableLogout] = useState(false);
  function handleLogout() {
    setDisableLogout(true);
    if (logoutLogic) {
      logoutLogic();
    }
  }

  return (
    <div className='profile'>
      <div className='profile dropdown'>
        <div className='dropdown-toggle' data-toggle='dropdown'>
          <img src={avatar ?? avatarDefault} alt='avatar' />
          <a href='/#' className='user-name' id='user-name'>{username}</a>
        </div>
        <div className='dropdown-menu'>
          <Button disabled={disableLogout} className='dropdown-item'>
            <Link className='go-to-info' to='/information'>{t('language.change_information')}</Link>
          </Button>
          <Button disabled={disableLogout} className='dropdown-item'>
            <Link className='go-to-info' to='/information?action=change-password'>{t('language.change_password')}</Link>
          </Button>
          <Button disabled={disableLogout} className='dropdown-item' onClick={handleLogout}>
            {disableLogout && <Spinner size='sm' animation='border' />}
            {t('language.logout')}
          </Button>
        </div>
      </div>
    </div>
  );
}

Profile.propTypes = {
  logoutLogic: PropTypes.func
};

Profile.defaultProps = {
  logoutLogic: null
};

export default Profile;
