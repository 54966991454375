import React, {lazy, Suspense} from 'react';
import {useSelector} from 'react-redux';
import {Route, Redirect} from 'react-router-dom';
import {BoxLoading} from 'react-loadingg';
import COLORS from "../../config/constants/color";
import componentLoader from '../loader'

function Private(props) {
  const {component, layout, pagePermission, ...rest} = props;
  const auth = useSelector(state => state.auth);
  const dynamicRedirect = (url) => {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: url,
              state: {from: props.location.pathname},
            }}
          />
        )}
      />
    );
  };

  const Component = lazy(() =>
    componentLoader(() =>
        import(`../../containers/${component}`)
      , 10)
  );

  if (!auth.authentic) {
    return dynamicRedirect('/login');
  }

  return (
    <Route
      {...rest}
      render={() => (
        <Suspense
          fallback={<div className='min-height-600'><BoxLoading size='large' color={COLORS.LOADING_COMPONENT} /></div>}
        >
          <Component {...props} />
        </Suspense>
      )}
    />
  );
}

export default Private;
