import React from 'react';
import Header from "../components/Header";
import Vietnamese from "../features/QuestionAnswer/vietnamese";
import Footer from "../components/Footer";

function QuestionsAnswers() {
  return (
    <article id='wrapper'>
      <Header />
      <div id='wrap'>
        <Vietnamese />
      </div>
      <Footer />
    </article>
  )
}

export default QuestionsAnswers;