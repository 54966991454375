const INFO_USER = {
    "NO_INFORMATION": "No Information about this"
}
const ACADEMIC_LEVEL_VN = {
    1: 'Phổ thông',
    2: 'Trung cấp/Học nghề',
    3: 'Cao đẳng',
    4: 'Đại học/Cử nhân',
    5: 'Thạc sĩ',
    6: 'Tiến sĩ',
}

const ACADEMIC_LEVEL_EN = {
    1: 'Universal',
    2: 'Intermediate / Vocational',
    3: 'College',
    4: 'University / Bachelor',
    5: 'Master',
    6: 'Doctor',
}

const ACADEMIC_LEVEL_ESP = {
    1: 'Universal',
    2: 'Intermedio / Aprendizaje',
    3: 'Universidad',
    4: 'Universidad / Licenciatura',
    5: 'Maestro',
    6: 'Doctor',
}
const JOB_VN = {
    1: 'Học sinh/Sinh viên',
    2: 'Nhân viên văn phòng',
    3: 'Nội trợ',
    4: 'Khác',
}

const JOB_EN = {
    1: 'Student',
    2: 'Office staff',
    3: 'Housewife',
    4: 'Other',
}

const JOB_ESP = {
    1: 'Estudiante',
    2: 'Personal de oficina',
    3: 'Ama de casa',
    4: 'Otro',
}
export default INFO_USER;
export {
    ACADEMIC_LEVEL_EN,
    ACADEMIC_LEVEL_ESP,
    ACADEMIC_LEVEL_VN,
    JOB_VN,
    JOB_EN,
    JOB_ESP
}
