import LANGUAGE_CONFIG, {
    WEEKDAY_VN,
    WEEKDAY_ESP,
    WEEKDAY_EN,
    EMOJI_VN,
    EMOJI_EN,
    EMOJI_ESP,
    RELATIONSHIP_VN,
    RELATIONSHIP_EN,
    RELATIONSHIP_ESP
} from "../../config/constants/language";
import {
    ACADEMIC_LEVEL_EN,
    ACADEMIC_LEVEL_ESP,
    ACADEMIC_LEVEL_VN,
    JOB_EN, JOB_ESP,
    JOB_VN
} from "../../config/constants/infoUser";

import {
    ACCOUNT_TYPE_EN,
    ACCOUNT_TYPE_ESP,
    ACCOUNT_TYPE_VN,
    LEVEL_EN,
    LEVEL_ESP,
    LEVEL_VN, REASON_SWITCH_EN, REASON_SWITCH_ESP, REASON_SWITCH_VN
} from "../../config/constants/infoExpert";

const GetLanguageConfigCode = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.VN
        case LANGUAGE_CONFIG.EN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.EN
        case LANGUAGE_CONFIG.ESP:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.ESP
        default:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.EN
    }
};

const GetLanguageWeekDay = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return WEEKDAY_VN
        case LANGUAGE_CONFIG.EN:
            return WEEKDAY_EN
        case LANGUAGE_CONFIG.ESP:
            return WEEKDAY_ESP
        default:
            return WEEKDAY_EN
    }
}

const GetLanguageLocal = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.VN
        case LANGUAGE_CONFIG.EN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.EN
        case LANGUAGE_CONFIG.ESP:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.ESP
        default:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.EN
    }
}
const GetLanguageJob = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return JOB_VN
        case LANGUAGE_CONFIG.EN:
            return JOB_EN
        case LANGUAGE_CONFIG.ESP:
            return JOB_ESP
        default:
            return JOB_EN
    }
}
const GetLanguageAcademicLevel = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return ACADEMIC_LEVEL_VN
        case LANGUAGE_CONFIG.EN:
            return ACADEMIC_LEVEL_EN
        case LANGUAGE_CONFIG.ESP:
            return ACADEMIC_LEVEL_ESP
        default:
            return ACADEMIC_LEVEL_EN
    }
}
const GetLanguageEmoji = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return EMOJI_VN
        case LANGUAGE_CONFIG.EN:
            return EMOJI_EN
        case LANGUAGE_CONFIG.ESP:
            return EMOJI_ESP
        default:
            return EMOJI_EN
    }
};
const GetRelationship = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return RELATIONSHIP_VN
        case LANGUAGE_CONFIG.EN:
            return RELATIONSHIP_EN
        case LANGUAGE_CONFIG.ESP:
            return RELATIONSHIP_ESP
        default:
            return RELATIONSHIP_EN
    }
};

const GetAccountType = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return ACCOUNT_TYPE_VN;
        case LANGUAGE_CONFIG.EN:
            return ACCOUNT_TYPE_EN;
        case LANGUAGE_CONFIG.ESP:
            return ACCOUNT_TYPE_ESP;
        default:
            return ACCOUNT_TYPE_EN;
    }
};

const GetLevelExpert = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return LEVEL_VN;
        case LANGUAGE_CONFIG.EN:
            return LEVEL_EN;
        case LANGUAGE_CONFIG.ESP:
            return LEVEL_ESP;
        default:
            return LEVEL_EN;
    }
};

const GetReasonSwitch = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return REASON_SWITCH_VN;
        case LANGUAGE_CONFIG.EN:
            return REASON_SWITCH_EN;
        case LANGUAGE_CONFIG.ESP:
            return REASON_SWITCH_ESP;
        default:
            return REASON_SWITCH_EN;
    }
}


export default GetLanguageConfigCode
export {
    GetLanguageLocal,
    GetLanguageWeekDay,
    GetLanguageJob,
    GetLanguageAcademicLevel,
    GetLanguageEmoji,
    GetRelationship,
    GetAccountType,
    GetLevelExpert,
    GetReasonSwitch
}
