const LANGUAGE_CONFIG = {
  EN: "en",
  VN: "vn",
  ESP: "esp",
  LANGUAGE_CODE_CONFIG: {
    VN: 1,
    EN: 2,
    ESP: 3
  },
  LANGUAGE_CODE_LOCAL: {
    VN: 'vi',
    EN: 'en',
    ESP: 'es'
  },
  MONTH_NAME_VN: {
    1: "Tháng 1",
    2: "Tháng 2",
    3: "Tháng 3",
    4: "Tháng 4",
    5: "Tháng 5",
    6: "Tháng 6",
    7: "Tháng 7",
    8: "Tháng 8",
    9: "Tháng 9",
    10: "Tháng 10",
    11: "Tháng 11",
    12: "Tháng 12",
  },
  MONTH_NAME_EN: {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  },
  MONTH_NAME_ESP: {
    1: "Enero",
    2: "Febrero",
    3: "Marzo",
    4: "Abril",
    5: "Mayo",
    6: "Junio",
    7: "Julio",
    8: "Agosto",
    9: "Septiembre",
    10: "Octubre",
    11: "Noviembre",
    12: "Diciembre",
  }
};

const WEEKDAY_VN = {
  0: 'CN',
  1: 'T2',
  2: 'T3',
  3: 'T4',
  4: 'T5',
  5: 'T6',
  6: 'T7',
}

const WEEKDAY_EN = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat',
}

const WEEKDAY_ESP = {
  0: 'Do',
  1: 'Lu',
  2: 'Ma',
  3: 'Mi',
  4: 'Ju',
  5: 'Vi',
  6: 'Sa',
}

const WEEKDAY_VN_FULL = {
  0: 'Chủ Nhật',
  1: 'Thứ Hai',
  2: 'Thứ Ba',
  3: 'Thứ Tư',
  4: 'Thứ Năm',
  5: 'Thứ Sáu',
  6: 'Thứ Bẩy',
}

const WEEKDAY_EN_FULL = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
}

const WEEKDAY_ESP_FULL = {
  0: 'domingo',
  1: 'lunes',
  2: 'martes',
  3: 'miércoles',
  4: 'jueves',
  5: 'viernes',
  6: 'sábado',
}

const EMOJI_VN = {
  search: 'Tìm kiếm',
  clear: 'Xóa', // Accessible label on "clear" button
  notfound: 'Không tìm thấy biểu tượng cảm xúc',
  skintext: 'Chọn tông màu da mặc định của bạn',
  categories: {
    search: 'Kết quả Tìm kiếm',
    recent: 'Thường được Sử dụng',
    smileys: 'Mặt cười & Cảm xúc',
    people: 'Con người & Cơ thể',
    nature: 'Động vật & Thiên nhiên',
    foods: 'Đồ ăn & Đồ uống',
    activity: 'Hoạt động',
    places: 'Du lịch & Địa điểm',
    objects: 'Đối tượng',
    symbols: 'Biểu tượng',
    flags: 'Cờ',
  },
  categorieslabel: 'Các danh mục biểu tượng cảm xúc', // Accessible title for the list of categories
  skintones: {
    1: 'Màu da mặc định',
    2: 'Tông màu da sáng',
    3: 'Tông màu Da sáng Trung bình',
    4: 'Tông màu Da Trung bình',
    5: 'Tông màu Da Trung bình-Tối',
    6: 'Tông màu da tối',
  },
}

const EMOJI_EN = {
  search: 'Search',
  clear: 'Clear', // Accessible label on "clear" button
  notfound: 'No Emoji Found',
  skintext: 'Choose your default skin tone',
  categories: {
    search: 'Search Results',
    recent: 'Frequently Used',
    smileys: 'Smileys & Emotion',
    people: 'People & Body',
    nature: 'Animals & Nature',
    foods: 'Food & Drink',
    activity: 'Activity',
    places: 'Travel & Places',
    objects: 'Objects',
    symbols: 'Symbols',
    flags: 'Flags',
    custom: 'Custom',
  },
  categorieslabel: 'Emoji categories', // Accessible title for the list of categories
  skintones: {
    1: 'Default Skin Tone',
    2: 'Light Skin Tone',
    3: 'Medium-Light Skin Tone',
    4: 'Medium Skin Tone',
    5: 'Medium-Dark Skin Tone',
    6: 'Dark Skin Tone',
  },
}

const EMOJI_ESP = {
  search: 'Buscar',
  clear: 'Clara',
  notfound: 'No se encontraron emojis',
  skintext: 'Elija su tono de piel predeterminado',
  categories: {
    search: 'Resultados de la búsqueda',
    recent: 'Usado con frecuencia',
    smileys: 'Emoticonos y emoción',
    people: 'Personas y cuerpo',
    nature: 'Animales y naturaleza',
    foods: 'Comida y bebida',
    activity: 'Actividad',
    places: 'Viajes y lugares',
    objects: 'Objetos',
    symbols: 'Símbolos',
    flags: 'Banderas',
    custom: 'Personalizado',
  },
  categorieslabel: 'Categorías de emoji',
  skintones: {
    1: 'Tono de piel predeterminado',
    2: 'Tono de piel claro',
    3: 'Tono de piel medio-claro',
    4: 'Tono de piel medio',
    5: 'Tono de piel medio oscuro',
    6: 'Tono de piel oscuro',
  },
}
const RELATIONSHIP_VN = {
  1: 'Bố/Mẹ',
  2: 'Ông/Bà',
  3: 'Con',
  4: 'Anh/Chị/Em',
  5: 'Bạn bè',
  6: 'Chồng/Vợ',
  7: 'Khác',
}

const RELATIONSHIP_EN = {
  1: 'Mom/Dad',
  2: 'Grandfather/Grandmother',
  3: 'Child',
  4: 'Brother/Sister',
  5: 'Friends',
  6: 'Husband/Wife',
  7: 'Other',
}



const RELATIONSHIP_ESP = {
  1: 'Mamá/Papá',
  2: 'Abuelo/abuela',
  3: 'Niño',
  4: 'Hermano/Hermana',
  5: 'Amigos',
  6: 'Esposo/Esposa',
  7: 'Otro',
}

export default LANGUAGE_CONFIG;
export {
  WEEKDAY_VN,
  WEEKDAY_EN,
  WEEKDAY_ESP,
  WEEKDAY_EN_FULL,
  WEEKDAY_VN_FULL,
  WEEKDAY_ESP_FULL,
  EMOJI_VN,
  EMOJI_EN,
  EMOJI_ESP,
  RELATIONSHIP_VN,
  RELATIONSHIP_EN,
  RELATIONSHIP_ESP
}
