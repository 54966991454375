import React from 'react';
import "./policy.css"

function Vietnamese() {
  return (
    <div className='parent'>
      <p className='title text-color-primary'><strong>CHÍNH SÁCH QUYỀN RIÊNG TƯ</strong></p>
      <p className='p2'>Cám ơn quý khách (“Khách hàng”) đã truy cập vào trang điện tử www.vmood.org (“Trang điện tử”) và
        sử dụng Phần mềm Vmood (“Vmood”) được sở hữu và vận hành bởi Viện Dân số, Sức khỏe và Phát triển (“Viện”). Chúng
        tôi tôn trọng và cam kết sẽ bảo mật những thông tin mang tính riêng tư của Khách hàng. Ngoài ra, với Viện, các
        thông tin y tế của Khách hàng được lưu trữ theo chuẩn HIPAA (Tiêu chuẩn về Quyền riêng tư đối với Thông tin y tế
        nhận dạng cá nhân theo Đạo luật về Trách nhiệm Giải trình và Cung cấp Thông tin Y tế của Hoa Kỳ), đây là chuẩn
        bảo mật dữ liệu y khoa khắt khe nhất trên toàn thế giới. Vì vậy, Khách hàng hoàn toàn có thể yên tâm về tính bảo
        mật đối với các thông tin này khi sử dụng Trang điện tử và dịch vụ của Viện. Xin vui lòng đọc bản chính sách
        quyền riêng tư dưới đây (“Chính sách quyền riêng tư”) để hiểu hơn những cam kết mà chúng tôi thực hiện nhằm tôn
        trọng và bảo vệ quyền lợi của Khách hàng và bảo mật thông tin do Khách hàng cung cấp.
      </p>
      <p className='p2'>Chính sách quyền riêng tư sẽ giải thích mục đích và phương thức chúng tôi tiếp nhận, lưu trữ, sử
        dụng và (trong một số trường hợp nhất định) tiết lộ thông tin cá nhân của Khách hàng. Chính sách cũng sẽ giải
        thích các bước chúng tôi thực hiện để bảo mật thông tin cá nhân của Khách hàng. Cuối cùng, Chính sách quyền
        riêng tư sẽ giải thích quyền lựa chọn của Khách hàng về việc thu thập, sử dụng và tiết lộ thông tin cá nhân của
        mình.
      </p>
      <p className='p2'>Bảo vệ dữ liệu cá nhân và gây dựng được niềm tin cho Khách hàng là vấn đề rất quan trọng với
        chúng tôi. Vì vậy, chúng tôi sẽ dùng tên và các thông tin khác liên quan đến Khách hàng tuân thủ theo nội dung
        của Chính sách quyền riêng tư. Chúng tôi chỉ thu thập những thông tin cần thiết liên quan đến các giao dịch được
        thực hiện trên Trang điện tử.
      </p>
      <p className='p2'>Chúng tôi sẽ giữ thông tin của khách hàng trong thời gian và mục đích được quy định trong Chính
        sách quyền riêng tư này. Khách hàng có thể truy cập vào Trang điện tử mà không cần phải cung cấp chi tiết cá
        nhân dưới chế độ ẩn danh khi không đăng nhập vào tài khoản.
      </p>
      <p className='p1'><strong>PHẠM VI THU THẬP VÀ SỬ DỤNG THÔNG TIN</strong></p>
      <p className='p2'>Khách hàng có thể cung cấp cho chúng tôi những thông tin của mình bằng cách điền vào phần đăng
        ký trong phần mềm Vmood hoặc trên Trang điện tử bao gồm nhưng không giới hạn bởi những thông tin như: (i) Các
        thông tin cá nhân, gồm: tên, ngày sinh, giới tính, địa chỉ, số điện thoại, địa chỉ thư điện tử (email); (ii) Các
        tài liệu, thông tin liên quan đến việc sử dụng dịch vụ, gồm: sách, dụng cụ hỗ trợ, hướng dẫn và giải pháp luyện
        tập; (iii) Những tài liệu hoặc thông tin khác (nếu có).
      </p>
      <p className='p2'>Khách hàng phải tự chịu trách nhiệm về tính hợp pháp, chính xác và trung thực của các tài liệu
        và thông tin do Khách hàng cung cấp và chúng tôi không có nghĩa vụ xác minh tính chính xác của các tài liệu hoặc
        thông tin đó.
      </p>
      <p className='p2'><strong>Cung cấp hàng hóa, dịch vụ</strong></p>
      <p className='p2'>Chúng tôi thu thập, lưu trữ và xử lý thông tin của Khách hàng nhằm sử dụng cho quá trình tư vấn,
        hỗ trợ điều trị, cung cấp hàng hóa, dịch vụ và cho những thông báo sau này, cụ thể:
      </p>
      <p className='p2'>Chúng tôi sẽ dùng thông tin Khách hàng đã cung cấp để cung cấp thông tin yêu cầu thông qua Trang
        điện tử và phần mềm Vmood và theo yêu cầu của Khách hàng. Ngoài ra, chúng tôi có thể sử dụng các thông tin đó để
        xác minh và thực hiện giao dịch trực tuyến, kiểm toán việc tải dữ liệu từ Trang điện tử; cải thiện bố cục và nội
        dung Trang điện tử và điều chỉnh cho phù hợp với người dùng; nhận diện khách truy cập, gửi thông tin bao gồm
        thông tin sản phẩm, nếu Khách hàng không có hành động từ chối. Khách hàng có thể từ chối nhận thông tin của
        chúng tôi bất kỳ lúc nào.
      </p>
      <p className='p2'>Chúng tôi có thể chuyển tên và địa chỉ của Khách hàng cho bên thứ ba để thực hiện việc giao hàng
        (ví dụ: công ty chuyển phát nhanh, bưu điện).
      </p>
      <p className='p2'>Chi tiết đơn đặt hàng của Khách hàng được chúng tôi lưu giữ và sẽ không công khai trực tiếp vì
        lí do bảo mật. Sau khi hoàn thành thanh toán trực tuyến, các thông tin đó sẽ được sử dụng theo đúng các quy định
        bảo mật của pháp luật.
      </p>
      <p className='p2'><strong>Nghiên cứu cải thiện và nâng cao chất lượng dịch vụ</strong></p>
      <p className='p2'>Chúng tôi có thể dùng thông tin cá nhân của Khách hàng cho mục đích nghiên cứu. Mọi thông tin cá
        nhân và liệu trình trị liệu của khách hàng sẽ được mã hóa, được ẩn và được dùng để thống kê. Khách hàng có thể
        từ chối không tham gia bất cứ lúc nào. Bất kỳ câu trả lời nào cho khảo sát hoặc thăm dò mà chúng tôi yêu cầu
        Khách hàng thực hiện sẽ không được chuyển cho bên thứ ba. Khách hàng có thể được yêu cầu tiết lộ thư điện tử cá
        nhân nếu muốn tham gia khảo sát. Câu trả lời sẽ được lưu riêng biệt với thư điện tử của Khách hàng.
      </p>
      <p className='p2'>Khách hàng sẽ nhận được thông tin về Viện, phần mềm Vmood, Trang điện tử, các trang điện tử
        khác, các sản phẩm, dịch vụ, bản tin hoặc bất cứ thông tin gì liên quan đến Vmood hoặc các đối tác, các bên liên
        quan. Nếu Khách hàng không muốn nhận những thông tin này, vui lòng nhấn vào đường dẫn từ chối trong bất kỳ thư
        điện tử nào chúng tôi gửi cho Khách hàng. Trong vòng 7 ngày kể từ ngày nhận được chỉ dẫn của Khách hàng, chúng
        tôi sẽ ngừng cung cấp các thông tin nói trên. Chúng tôi có thể liên lạc với Khách hàng để xác thực yêu cầu.
      </p>
      <p className='p2'><strong>Đối tác thứ ba và liên kết</strong></p>
      <p className='p2'>Chúng tôi có thể chuyển thông tin của Khách hàng cho các đối tác thứ ba (bao gồm nhưng không
        giới hạn bởi các bên thứ ba như nhận tiền thanh toán, phân tích dữ liệu và hỗ trợ dịch vụ khách hàng) trong
        khuôn khổ quy định của Chính sách quyền riêng tư. Chúng tôi có thể trao đổi thông tin với bên thứ ba với mục
        đích chống gian lận và giảm rủi ro tín dụng. Chúng tôi sẽ mã hóa thông tin để đảm bảo thông tin được bảo mật
        trước khi chuyển cơ sở dữ liệu trong đó bao gồm thông tin cá nhân của Khách hàng cho bên thứ ba. Trong khuôn khổ
        Chính sách quyền riêng tư, chúng tôi không tiết lộ dữ liệu cá nhân của Khách hàng cho bên thứ ba mà không được
        đồng ý trước trừ khi điều này là cần thiết cho việc thực hiện các điều khoản trong Chính sách quyền riêng tư
        hoặc chúng tôi được yêu cầu phải làm như vậy theo quy định của pháp luật.
      </p>
      <p className='p2'>Hệ thống theo dõi hành vi của khách hàng được chúng tôi sử dụng để thu thập các thông tin như độ
        tuổi, giới tính ….
      </p>
      <p className='p2'>Chúng tôi và các nhà cung cấp bên thứ ba có thể sử dụng cookies của bên thứ ba để thu thập thông
        tin, tối ưu hóa và phục vụ cho mục đích quảng cáo dựa trên lần truy cập Trang điện tử của người dùng trong quá
        khứ.
      </p>
      <p className='p1'><strong>ĐƯỜNG DẪN ĐẾN CÁC TRANG ĐIỆN TỬ KHÁC</strong></p>
      <p className='p2'>Trang điện tử của chúng tôi, tùy từng thời điểm, có thể liên kết đến các trang điện tử khác. Nếu
        Khách hàng theo đường dẫn để truy cập vào bất kỳ trang điện tử nào, mọi thông tin mà Khách hàng cung cấp trên
        trang điện tử đó sẽ được truyền và lưu trữ trực tiếp bởi chủ sở hữu của các trang điện tử đó và Khách hàng sẽ
        phải tuân thủ chính sách quyền riêng tư của các trang điện tử đó.
      </p>
      <p className='p2'>Chúng tôi sẽ không chịu bất kỳ trách nhiệm nào về nội dung, tính riêng tư, quy tắc bảo mật và
        chính sách của các trang điện tử nêu trên và Khách hàng sẽ phải tự kiểm tra chính sách quyền riêng tư của các
        trang điện tử đó trước khi gửi dữ liệu.
      </p>
      <p className='p1'><strong>CAM KẾT BẢO MẬT THÔNG TIN CÁ NHÂN KHÁCH HÀNG</strong></p>
      <p className='p2'>Chúng tôi có biện pháp thích hợp về kỹ thuật và an ninh để ngăn chặn truy cập trái phép hoặc
        trái pháp luật hoặc mất mát, tiêu hủy hoặc thiệt hại cho thông tin của Khách hàng. Khi thu thập dữ liệu trên
        Trang điện tử, chúng tôi thu thập chi tiết cá nhân của Khách hàng trên máy chủ với hệ thống tường lửa đảm bảo an
        toàn. Chúng tôi duy trì các biện pháp bảo vệ vật lý và điện tử trong mối liên kết với việc thu thập, lưu trữ và
        tiết lộ thông tin của Khách hàng. Để thực hiện các thủ tục an toàn, chúng tôi có thể yêu cầu giấy tờ chứng minh
        trước khi tiết lộ thông tin cá nhân theo yêu cầu của Khách hàng.
      </p>
      <p className='p2'>Chúng tôi khuyến cáo Khách hàng không nên cung cấp thông tin thanh toán với bất kỳ ai dưới bất
        kỳ hình thức nào, chúng tôi không chịu trách nhiệm về những tổn thất mà Khách hàng có thể gánh chịu từ việc trao
        đổi thông tin của Khách hàng nói trên.
      </p>
      <p className='p2'>Khách hàng tuyệt đối không sử dụng bất kỳ chương trình, công cụ hay hình thức nào khác để can
        thiệp vào hệ thống hay làm thay đổi cấu trúc dữ liệu. Nghiêm cấm việc phát tán, truyền bá hay cổ vũ cho bất kỳ
        hoạt động nào nhằm can thiệp, phá hoại hay xâm nhập vào dữ liệu của hệ thống Trang điện tử. Mọi cá nhân, tổ chức
        vi phạm sẽ bị tước bỏ mọi quyền lợi cũng như sẽ bị truy tố trước pháp luật nếu cần thiết.
      </p>
      <p className='p2'>Mọi thông tin giao dịch sẽ được bảo mật và không tiết lộ cho bên thứ ba trừ khi được quy định
        tại Chính sách quyền riêng tư này hoặc khi được yêu cầu bởi các cơ quan có thẩm quyền hoặc theo quy định của
        pháp luật.
      </p>
      <p className='p2'>Các điều kiện, điều khoản và nội dung của Trang điện tử bao gồm Chính sách quyền riêng tư này
        được điều chỉnh và giải thích bởi pháp luật Việt Nam. Mọi tranh chấp và khiếu kiện phát sinh từ hoặc liên quan
        đến các điều kiện, điều khoản và nội dung của Trang điện tử bao gồm Chính sách quyền riêng tư này sẽ được giải
        quyết theo quy định tại các Điều khoản và Điều kiện dành cho Khách hàng (Vui lòng xem tại đây).
      </p>
      <p className='p1'><strong>PHƯƠNG TIỆN VÀ CÔNG CỤ ĐỂ KHÁCH HÀNG TIẾP CẬN VÀ CHỈNH SỬA DỮ LIỆU CÁ NHÂN CỦA MÌNH</strong>
      </p>
      <p className='p2'>Khách hàng có quyền yêu cầu chúng tôi điều chỉnh sai sót trong dữ liệu của Khách hàng mà không
        mất phí. Bất cứ lúc nào Khách hàng cũng có quyền yêu cầu chúng tôi ngừng sử dụng dữ liệu cá nhân của Khách hàng
        cho mục đích tiếp thị.
      </p>
      <p className='p2'>Khách hàng có thể thực hiện trực tuyến thông qua Trang điện tử, thông qua phần mềm Vmood hoặc
        liên hệ trực tiếp với chúng tôi theo thông tin được cung cấp tại Trang điện tử.
      </p>
      <p className='p1'><strong>SỬ DỤNG COOKIES</strong></p>
      <p className='p2'>Cookie là tập tin văn bản nhỏ có thể nhận dạng tên truy cập duy nhất từ máy tính của Khách hàng
        đến máy chủ của chúng tôi khi Khách hàng truy cập vào các trang nhất định trên Trang điện tử và sẽ được lưu bởi
        trình duyệt lên ổ cứng máy tính của Khách hàng. Cookie được dùng để nhận dạng địa chỉ IP và lưu lại thời gian.
        Chúng tôi dùng cookie để tiện cho Khách hàng truy cập vào Trang điện tử (ví dụ: ghi nhớ tên truy cập khi Khách
        hàng muốn vào thay đổi lại giỏ mua hàng mà không cần phải nhập lại địa chỉ thư điện tử của mình) và không đòi
        hỏi thêm bất kỳ thông tin nào (ví dụ: cho mục đích quảng cáo).
      </p>
      <p className='p2'>Trình duyệt của Khách hàng có thể được thiết lập không sử dụng cookie nhưng điều này sẽ hạn chế
        quyền sử dụng của Khách hàng trên Trang điện tử. Chúng tôi cam kết cookie không bao gồm bất kỳ chi tiết cá nhân
        riêng tư nào và an toàn với virus. Muốn biết thêm về cookie, vui lòng truy cập Trang điện tử
        http://www.allaboutcookies.org hoặc để tìm hiểu cách loại bỏ cookie khỏi trình duyệt, vui lòng vào
        http://www.allaboutcookies.org/manage-cookies/index.html.
      </p>
      <p className='p1'><strong>THỜI GIAN LƯU TRỮ THÔNG TIN</strong></p>
      <p className='p2'>Chúng tôi sẽ lưu trữ các thông tin cá nhân do Khách hàng cung cấp trên các hệ thống nội bộ của
        chúng tôi trong quá trình cung cấp dịch vụ cho Khách hàng hoặc cho đến khi hoàn thành mục đích thu thập hoặc khi
        Khách hàng có yêu cầu hủy các thông tin đã cung cấp.
      </p>
      <p className='p1'><strong>CẬP NHẬT VÀ NGÔN NGỮ CỦA CHÍNH SÁCH QUYỀN RIÊNG TƯ</strong></p>
      <p className='p2'>Khách hàng đồng ý rằng Chúng tôi có quyền sửa đổi, thay đổi Chính sách quyền riêng tư này tại
        bất kỳ thời điểm nào mà không cần thông báo trước. Các sửa đổi sẽ được cập nhật trên Trang điện tử và có hiệu
        lực kể từ ngày quyết định về việc sửa đổi Chính sách quyền riêng tư có hiệu lực. Việc Khách hàng tiếp tục sử
        dụng Trang điện tử sau khi Chính sách quyền riêng tư sửa đổi được công bố và áp dụng đồng nghĩa với việc Khách
        hàng chấp nhận Chính sách quyền riêng tư sửa đổi đó.
      </p>
      <p className='p2'>Theo quy định pháp luật, ngôn ngữ được ưu tiên sử dụng và tham chiếu là tiếng Việt. Trong trường
        hợp có sự mâu thuẫn trong cách giải thích giữa bản tiếng Việt và các ngôn ngữ khác thì bản tiếng Việt sẽ được ưu
        tiên tham chiếu.
      </p>
      <p className='p1'><strong>ĐỊA CHỈ LIÊN HỆ</strong></p>
      <p className='p3'><strong>Trụ sở chính</strong>: Viện Dân số, Sức khỏe và Phát triển (PHAD)</p>
      <p className='p3'>Trụ sở chính: Số 18, ngõ 132, Hoa Bằng, Cầu Giấy, Hà Nội<br /> <strong>Văn phòng điều hành/ địa chỉ thư tín</strong>: Tầng 14, tòa nhà Icon4, số 1-3 Cầu Giấy, Đống Đa, Hà Nội<br /> <strong>Điện thoại</strong>:
        (+84) 2473 000 988
      </p>
      <p className='p3'><strong>Thư điện tử (email):</strong> <span className='text-color-primary'>vmood@phad.org</span></p>
      <p className='p4' />
      <p className='p4' />
      <p className='p5' />
    </div>
  );
}

export default Vietnamese;