import { combineReducers } from "redux";
import langReducer from "./Language";
import authReducer from "./Auth";

const rootReducer = combineReducers({
  language: langReducer,
  auth: authReducer
});

export default rootReducer;
