import ACTION from "../../config/constants/action";

const authLogin = (auth) => {
  return {
    type: ACTION.LOGIN_SUCCESS,
    payload: auth,
  };
};

const Logout = (flag) => {
  return {
    type: ACTION.LOGOUT,
    payload: flag
  }

};

const ChangeTimeRelyPhq9 = (time) => {
  return {
    type: ACTION.UPDATE_TIME_PHQ9,
    payload: time
  }

};

const UpdateInfo  = (auth) => {
  return {
    type: ACTION.UPDATE_INFO,
    payload: auth
  }
};

export {authLogin, Logout, ChangeTimeRelyPhq9, UpdateInfo};