export default [
    {
        key: 1,
        path: '/login',
        component: 'login',
        auth: false
    },
    {
        key: 2,
        path: '/home',
        component: 'home',
        auth: true,
    },
    {
        key: 3,
        path: '/client/:id',
        component: 'patientDetail',
        auth: true,
    },
    {
        key: 4,
        path: '/client',
        component: 'patientList',
        auth: true,
    },
    {
      key: 5,
      path: '/information',
      component: 'information',
      auth: true,
    },
    {
        key: 6,
        path: '/statistical',
        component: 'statistical',
        auth: true,
    }

];
