import ACTION from "../../config/constants/action";
import {setLanguageForBrowser} from "../../repositories/localStorage/set";
import {getLanguageSetUp} from "../../repositories/localStorage/get";

const initialState = {
  lang: getLanguageSetUp() || 'en',
};

const langReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION.CHANGE_LANGUAGE: {
      const newLang = action.payload.language;
      setLanguageForBrowser(newLang);
      return {
        ...state,
        lang: newLang,
      };
    }
    default:
      return state;
  }
};

export default langReducer;
