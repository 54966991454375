import React from 'react';
import "./policy.css"

function English() {
  return (
    <div id='policy' className='parent'>
      <p className='title text-color-primary'><strong>PRIVACY POLICY</strong></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p2'>Thank you ("Customer") for visiting the website www.vmood.org ("Website") and use Vmood Software&nbsp;
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        ("Vmood") owned and operated by the Institute of Population, Health and Development ("Institute"). We respect
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        and are committed to protecting the privacy of Customer's information. In addition, with the Institute, the
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Customer's medical information is stored according to HIPAA standards (Personal Identifiable Medical Information
        Privacy Standard under the US Medical Accountability and Accountability Act), This is the most rigorous medical
        data security standard in the world. Therefore, Customers can be completely assured of the confidentiality of
        this information when using the Website and the services of the Institute. Please read the following privacy
        policy (&ldquo;Privacy policy&rdquo;) to better understand the commitments we make to respect and protect the
        interests of the Customer and the confidentiality of the information provided by the Customer.
      </p>
      <p className='p2'>The Privacy Policy will explain the purpose and how we receive, store, use and (in certain
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        cases) disclosing Customer's personal information. The policy will also explain the steps we take to secure the
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Client's personal information. Finally, the Privacy Policy will explain the Customer's choice regarding
        collection, use and disclosure of its personal information.
      </p>
      <p className='p2'>Protecting personal data and building trust for customers is very important to us. Therefore, we
        will use the name and other information related to the Customer in accordance with the content of the Privacy
        Policy. We only collect necessary information relating to services provided by us and announced on our
        Website.
      </p>
      <p className='p2'>We will keep customer information for the time and for the purpose specified in this Privacy
        Policy. Customers can access the Website without having to provide personal details under the incognito mode
        when not logged into the account.
      </p>
      <p className='p1'><strong>SCOPE OF COLLECTING INFORMATION AND USE</strong></p>
      <p className='p2'>Customers can provide us with their information by completing the registration in the software
        or on the Website including but not limited to information such as: (i) Personal information, including: Name,
        date of birth, gender, address, phone number, email address (email); (ii) Documents and information related to
        the service use, including: books, aids, instructions and practice solutions; (iii) Other documents or
        information (if any).
      </p>
      <p className='p2'>Customer is solely responsible for the legality, accuracy and truthfulness of the documents and
        information provided by the Client and we are under no obligation to verify the accuracy of such documents or
        information.
      </p>
      <p className='p2'><strong>Provide goods and services</strong></p>
      <p className='p2'>We collect, store and process Customer information for the purpose of consulting, supporting
        treatment, supplying goods and services and for future notices, specifically:
      </p>
      <p className='p2'>We will use the information provided by the Customer to provide requested information through
        the website and Vmood software and at the request of the Customer. In addition, we may use such information to
        verify and conduct online transactions, audit data download from the Website; improve the layout and content of
        the Website and make it suitable for users; identify the visitor, send information including product
        information, if the Customer does not act on refusal. Customers can refuse to receive information from us at any
        time.
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p2'>We may pass on the Client's name and address to a third party for delivery (e.g. courier
        company, post office).
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p2'>Customer's order details are kept by us and will not be published directly for security reasons.
        After completing the online payment, such information will be used in accordance with the security regulations
        of the law.
      </p>
      <p className='p2'><strong>Research to improve and improve service quality</strong></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p2'>We may use the Client's personal information for research purposes. All client's personal
        information and therapy will be encrypted, hidden and used for statistics. Customers can refuse to participate
        at any time. Any response to a survey or survey we request to Customer to do will not be passed on to a third
        party. Customers may be asked to disclose personal e-mail if they wish to participate in the survey. The answer
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        will be saved separately from the Customer's email.
      </p>
      <p className='p2'>Customers will receive information about the Institute of PHAD, Vmood software, Website, other
        websites, products, services, newsletters or any information related to Vmood or partners, related parties. If
        the Customer does not want to receive this information, please click on the opt-out link in any email we send to
        the Customer. Within 7 days from the date of receiving the instructions from the Customer, we will stop
        providing the above information. We may contact the Client to verify the request.
      </p>
      <p className='p2'><strong>Third partner and affiliate</strong></p>
      <p className='p2'>We may pass on Customer information to third parties (Including but not limited to third parties
        such as receiving payments, data analytics and customer service support) within the framework of the Privacy
        Policy. We may exchange information with third parties for the purposes of fraud prevention and credit risk
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        reduction. We will encrypt information to ensure it is secure before transferring data including Customer's
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        personal information to third parties. In the framework of Privacy Policy, we do not disclose Customer's
        personal data to third parties without prior consent unless this is necessary for the implementation of the
        terms of the Privacy Policy or we are required to do so by law.
      </p>
      <p className='p2'>The customer behavior tracking system is used by us to collect information such as age, gender
        and mood variation of the clients&hellip;.
      </p>
      <p className='p2'>We and third-party vendors may use third-party cookies to collect information, optimize and
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        serve advertising purposes based on a user's past Website visit.
      </p>
      <p className='p1'><strong>LINKS TO OTHER ELECTRONIC SITES</strong></p>
      <p className='p2'>Our website may, from time to time, link to other websites. If the Customer follows the link to
        access any website, All information that the Customer provides on such websites will be transmitted and stored
        directly by the owner of such websites and the Customer will be required to comply with the privacy policy of
        those websites.
      </p>
      <p className='p2'>We will not be responsible for any responsibility for the content, privacy, security rules and
        policies of the above websites and the Customer will have to check the privacy policy of such websites before
        sending data.
      </p>
      <p className='p1'><strong>COMMITMENT TO PRIVATE CUSTOMER PERSONAL INFORMATION</strong></p>
      <p className='p2'>We take appropriate technical and security measures to prevent unauthorized or unlawful access
        or loss, destruction or damage to Customer information. When collecting data on the Website, we collect some of
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        the Customer's personal details on the server with a secure firewall system. We maintain physical and electronic
        safeguards in connection with the collection, storage and disclosure of Customer information. In order to
        perform security procedures, we may require a proof evidence before disclosing personal information at the
        request of the Client.
      </p>
      <p className='p2'>We will never ask or call you to ask for your payment information. We recommend that the
        Customer should not provide payment information to anyone in any way, we are not responsible for losses that the
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Customer may incur from the exchange Customer's information above.
      </p>
      <p className='p2'>Customers absolutely do not use any programs, tools or any other form to interfere in the system
        or change data structure. It is strictly forbidden to distribute, propagate or promote any activity aimed at
        interfering with, destroying or entering data of the Website system. All violating individuals and organizations
        will be deprived of all their rights and will be prosecuted before the law if necessary.
      </p>
      <p className='p2'>All transaction information will be kept confidential and not disclosed to third parties except
        as provided in this Privacy Policy or when required by competent authorities or as required by law.
      </p>
      <p className='p2'>The terms, conditions and content of the Site including this Privacy Policy are governed by and
        construed by the laws of Vietnam. Any dispute and claim arising out of or relating to the terms, conditions and
        content of the Website including this Privacy Policy will be resolved in accordance with the Terms and
        Conditions for Customer (Please see here).
      </p>
      <p className='p1'><strong>MEASURES AND TOOLS FOR CUSTOMERS TO ACCESS AND EDIT YOUR PERSONAL DATA</strong></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p2'>The Customer has the right to request us to correct errors in Customer's data at no charge. The
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Client has the right at any time to request that we stop using the Client's personal data for marketing
        purposes.
      </p>
      <p className='p2'>Customers can do this online through the Website, through the Vmood software or contact us
        directly according to the information provided at the Website.
      </p>
      <p className='p1'><strong>USE OF COOKIES</strong></p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p2'>A cookie is a small text file that can uniquely identify your username from Client's computer to
        our server when the Customer accesses certain pages on the Website and will be saved by the browser on the
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Customer's computer hard drive. Cookies are used to identify an IP address and to save time. We use cookies for
        the convenience of customers to access the Website (for example: remember username when Customers want to change
        shopping cart without having to re-enter their email address) and does not require any additional information
        (eg for advertising purposes).
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p2'>The Client's Browser may be set up not to use cookies, but this will limit the Client's use of
        the Website. We are committed to the cookie does not include any personal details that are private and safe with
        the virus. To know more about cookies, please visit the Website http://www.allaboutcookies.org Or to learn how
        to remove cookies from your browser, please visit http://www.allaboutcookies.org/manage-cookies/index.html.
      </p>
      <p className='p1'><strong>STORING INFORMATION</strong></p>
      <p className='p2'>We will store the personal information provided by the Customer on our internal systems in the
        course of providing services to the Customer or until the purpose of the collection is completed or when the
        Customer requests to destroy the information provided.
      </p>
      <p className='p1'><strong>UPDATE AND LANGUAGE OF THE PRIVACY POLICY</strong></p>
      <p className='p2'>Customer agrees that We reserve the right to amend, change this Privacy Policy at any time
        without prior notice. Modifications will be updated on the Website and will be effective from the effective date
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        of the decision to amend the Privacy Policy. The Customer's continued use of the Website after the revised
        Privacy Policy is published and applied means that the Customer accepts such revised Privacy Policy.
      </p>
      <p className='p2'>According to the law, the preferred language and reference language is Vietnamese. In case of
        any inconsistencies in the interpretation between the Vietnamese version and other languages, the Vietnamese
        version shall prevail.
      </p>
      <p className='p1'><strong>CONTACTING ADDRESS</strong></p>
      <p className='p3'><strong>Headquarters</strong>: Institute for Population, Health and Development (PHAD)</p>
      <p className='p3'><strong>Headquarters</strong>: No. 18, Lane 132, Hoa Bang, Cau Giay, Hanoi<br />
        <strong>Executive office / mailing address</strong>: Level 14, Icon4 Building, No. 1-3 Cau Giay, Dong Da, Hanoi<br />
        <strong>Phone</strong>: (+84) 2473 000 988
      </p>
      <p className='p3'><strong>Electric mail (email):</strong> <span className='s3'>vmood@phad.org</span></p>
      <p className='p4'>&nbsp;</p>
      <p className='p4'>&nbsp;</p>
      <p className='p5'>&nbsp;</p>
    </div>
  );
}

export default English;