import React from 'react';
import {useSelector} from "react-redux";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Vietnamese from "../features/Policy/vietnamese";
import LANGUAGE_CONFIG from "../config/constants/language";
import English from "../features/Policy/english";

function Policy() {
  const lang = useSelector(state => state.language.lang);
  const renderContent = () => {
    switch (lang) {
      case LANGUAGE_CONFIG.VN:
        return <Vietnamese />;
      case LANGUAGE_CONFIG.EN:
        return <English />
      case LANGUAGE_CONFIG.ESP:
        return <English />
      default:
        return <English />
    }
  }
  return (
    <article id='wrapper'>
      <Header />
      <div id='wrap'>
        {renderContent()}
      </div>
      <Footer />
    </article>
  )
}

export default Policy;