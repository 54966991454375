import axiosClient from "./axiosClient";

const notifyAPI = {
  getAllNotify: (accessToken, params) => {
    const url = "/experts/notification";
    return axiosClient.get(url, {
      headers: {
        "Access-Token": accessToken
      },
      params,
      validateStatus (status) {
        return status;
      }
    });
  },
};
export default notifyAPI;