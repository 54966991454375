import CryptoAES from 'crypto-js/aes'
import CryptoENC from 'crypto-js/enc-utf8';
import SECRET from "../../config/constants/secret";
import LANGUAGE_CONFIG from "../../config/constants/language";

const getAuthProfile = () => {
  return JSON.parse(localStorage.getItem("auth"))
};

const getAuthenticFlag = () => {
  return localStorage.getItem("authentic")
};
const getLanguageSetUp = () => {
  const lang = localStorage.getItem("lang");
  switch (lang) {
    case LANGUAGE_CONFIG.VN:
      return lang;
    case LANGUAGE_CONFIG.EN:
      return lang;
    case LANGUAGE_CONFIG.ESP:
      return lang;
    default:
      return LANGUAGE_CONFIG.EN;
  }
};

const getRememberPassword = () => {
  const data = JSON.parse(localStorage.getItem("rememberPassword"));
  if (data) {
    const decryptPassword = CryptoAES.decrypt(data.password, SECRET.SECRET_KEY);
    const newData = {
      ...data,
      password: decryptPassword.toString(CryptoENC)
    }
    return newData;
  }
  return data;
};

const FormatDate = (date, flag) => {
  const month = ((date.getMonth() + 1) < 10) ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const day = ((date.getDate()) < 10) ? `0${date.getDate()}` : date.getDate();
  if (flag) {
    return `${day}/${month}/${date.getFullYear()}`
  }
  return `${date.getFullYear()}-${month}-${day}`
}

const getTimeZoneName = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export {
  getAuthProfile,
  getAuthenticFlag,
  getLanguageSetUp,
  getRememberPassword,
  FormatDate,
  getTimeZoneName
}
