import React, {useEffect} from 'react';
import './App.css';
import "./config/i18n";
import {useTranslation} from "react-i18next";
import RouterDom from "./router";
import {SocketProvider} from "./contexts/SockerContext";

function App() {
  const {t} = useTranslation();
  useEffect(() => {
    document.title = t('language.title_page');
  });
  return (
    <SocketProvider>
      <RouterDom />
    </SocketProvider>
  );
}

export default App;
