import React from 'react';

function English() {
  return (
    <div id='terms-of-use' className='parent'>
      <p className='title text-color-primary'><strong>TERMS AND CONDITIONS OF USING THE SERVICE</strong></p>
      <p className='p2'>Initial effective date: 11/2020</p>
      <p className='p2'>Last modified date:</p>
      <p className='p2'>Version: 1.0.0</p>
      <p className='p3'>&nbsp;</p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p4'>Welcome to Vmood, our mobile and online service (hereinafter referred to as "Vmood", or "We", or
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        "Ours").
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p4'>Domain www.vmood.org (hereinafter referred to as "Website ") and the Vmood mobile app
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        ("<strong>Mobile Software "or" Vmood Application</strong>") is owned and managed by the Institute for Population, Health and
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Development (PHAD), hereinafter referred to as "We" or "Vmood&rdquo;, an Institute was established and operated
        under the state law of the Socialist Republic of Vietnam, <span className='s1'>have a scientific activity registration number</span>,
        Headquartered at No. 18, Lane 132, Hoa Bang, Cau Giay, Hanoi and operating office at 14th floor, ICON4 building,
        No. 1-3 Cau Giay street, Dong Da, Hanoi.
      </p>
      <p className='p4'>This Terms of Service applies to all visitors, data subjects, and others accessing the
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Service <strong>("User (s)" or "You" or "Customer")</strong>. This page explains the terms that You can use our
        Website, other websites maintained by Us in connection with our Apps, Your Use of the &ldquo;
        <strong>Vmood Application
          {/* eslint-disable-next-line react/no-unescaped-entities */}
        </strong>&rdquo;), Expert services are provided by Vmood's Doctors / Psychologists / Coaches
        (hereinafter referred to as &ldquo;<strong>Specialist Services</strong>&rdquo;) and / or &ldquo;
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <strong>Therapeutic services</strong>&rdquo; of Vmood is provided through Vmood Application (collectively, "<strong>Service (s)</strong>"). By accessing or using the Service, You represent that You have read, understood and agree to
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        be bound by this terms of service. ("<strong>Terms and condition </strong>") and the collection and use of Your
        Information as set forth in the Privacy Policy and the terms of any other Vmood policies that may apply to You
        whether You are a Registered User of Our Services or not. These Terms and Conditions, Privacy Policy and any
        other applicable terms and conditions hereinafter are collectively referred to as &ldquo;
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        <strong>Agreement "or"
          Contract
        </strong>&rdquo;. For more information regarding your use of data within Vmood, please read our
        Privacy Policy.
      </p>
      <p className='p5'><strong>NOTE: </strong></p>
      <p className='p4'>1. If You are intending to commit suicide or plan to carry out suicidal thoughts, or if You feel
        that You might endanger yourself or others, or if You have any serious mental health or mental health emergency,
        or if You are experiencing crisis or injury or abuse, please stop using the Services immediately and call the
        relevant emergency number or emergency medical services.
      </p>
      <p className='p4'>2. If You are under 18 years old, please read through
        <span className='s1'>Privacy Policy</span>by Vmood and <span className='s1'>Terms of Service </span>of Vmood with
        Your parent or legal guardian or check with Your Organization to understand eligibility prior to use. Vmood is
        suitable for people from full 18 years old to full 60 years old and should not be used by children under 13
        years old.
      </p>
      <p className='p4'>3. Please do not share Your Personal data (such as your full name, date of birth, gender,
        contact number, address, financial identifier, government-provided identifier) or your health-related data or
        any other sensitive data (such as preferences, religious or political opinions, financial data &hellip;) when
        you use Vmood<span className='s1'> Applications and Services</span>.
      </p>
      <p className='p4'>4. You understand that Your interaction with Vmood is through the App and is connected with a
        team of professionals. We also provide these <span className='s1'>instruct depression management skills, Positive thinking games and videos that address emotional problems </span>with
        the purpose is to provide evidence-based tools and techniques to manage emotions and promote mental health
        in <span className='s1'>the context of self-overcoming depression</span>.
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p4'>5. Vmood's services like <span className='s1'>self-detect depression, practice positive thinking, and solve problems, </span>It
        is not intended as a substitute for face-to-face psychotherapy or to provide a diagnosis or prognosis, treatment
        or cure of disease / condition / disorder or provision of any mental health services administered by the
        government of Vietnam, the Ministry of Health. This is a means of helping to facilitate, not to cure.
      </p>
      <p className='p4'>6. By using the Expert Services, you understand and agree that they are assigned to work with
        You and will be located remotely and may not be near where you live or your province / city.
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p4'>7. Vmood's services like <span className='s1'>Depression self-screening, positive thinking training, problem solving and related services are integrated into the software, thus </span>cannot
        and will not give a clinical word. In the event that you address your need for such counseling, you may be asked
        to seek advanced medical help or to see a <span className='s1'>psychiatrist</span>.
      </p>
      <p className='p4'>8. We do our best to keep Your chats as private and secure as outlined in the above
        <span className='s1'>Privacy Policy</span>. You can also secure your chats and device by following the best practices
        outlined in the <span className='s1'>Privacy Policy section</span>.
      </p>
      <p className='p4'>9. For safety and security reasons, We strongly recommend that You keep Your chats with the
        Vmood App private. We strongly recommend that You set Vmood App updates automatically in the app manager
        settings on your mobile device; to receive fixes and features based on the latest Vmood Apps. Always exit the
        Vmood App version in your mobile device using the back button before upgrading to a newer version to avoid
        losing ongoing or previous chats.
      </p>
      <p className='p5'><strong>VMOOD APPLICATIONS </strong></p>
      <p className='p4'>Vmood application is an electronic health aid app aimed at helping people with depression
        (&ldquo;Vmood&rdquo;) through which help clients to self-screen for depression, control and introduce treatments
        for people with depression in Vietnam, depending on your options, the ability to sign up and send messages with
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        a trained and highly qualified mental health professional ("Coach" or "Psychologist" or "Psychiatrist") to be
        able to use the assistive mechanisms built into the Vmood App and through the interface, have access to tools
        and techniques to manage your mood.
      </p>
      <p className='p4'>The Vmood app is available for both iOS and Android mobile systems. Your interaction with some
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        of VMood's services. Some services are limited in terms of intended use.
      </p>
      <p className='p4'>Vmood application provides and supports customers through tools and technical
        <span className='s1'>based on evidences</span>to manage emotions and promote mental health, <span className='s1'>as an early intervention tool </span>in
        the context of self-control. You have to choose to use some services like <span className='s1'>screen for depression, practice positive thinking, and solve problems </span>based
        on your own estimate needs, and agreed that this is just suitable for basic level of self-control. This is not
        intended as a substitute for face-to-face psychotherapy or to provide a prognostic diagnosis, treatment or cure
        of a disease / condition / disorder. Using the Vmood Application, You can monitor and manage Your moods, and
        learn evidence-based techniques that can make you feel better. Vmood Apps and Services are not intended for use
        during a crisis such as abuse or complex or severe mental health conditions, such as: intends to commit suicide,
        harm yourself and others, or for any medical emergency. Vmood Apps and Services cannot and will not give medical
        or clinical advice. It can only suggest that the user seeks advanced medical help.
      </p>
      <p className='p5'><strong>ELIGIBLE</strong></p>
      <p className='p4'>You can only Use the Service if You agree to this Agreement and form a binding contract with
        Vmood and only comply with all applicable laws, rules and regulations of the Laws of Vietnam. If You are between
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        13 and 18 years old, please read through Vmood's Privacy Policy and Vmood's Terms of Service together with Your
        parent or legal guardian and in that case, The agreement will be deemed to be a contract between PHAD and Your
        legal guardian, guardian or parent and to the extent permitted under applicable law, enforceable to You.
        Creating an account and / or Using the Service is strictly forbidden by anyone under the age of 13.
      </p>
      <p className='p4'>If your Organization designates another age limit, such as at least 18 or older, as a condition
        of using this Service, that limit will apply instead of the above.
      </p>
      <p className='p4'>Vmood reserves the right to refuse access to the Service or terminate Your account if the
        eligibility is not met, at its sole discretion. The Service is not available to any User who has previously been
        removed by Vmood from the Service.
      </p>
      <p className='p5'><strong>Vmood LICENSE</strong></p>
      <p className='p4'>Subject to the Terms and Conditions, You are granted a non-exclusive, limited, non-transferable
        license, may revoke Your freedom to use the Service solely for Your personal use and as permitted by the
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        features of the Service. Vmood reserves all rights not expressly granted herein in Vmood's Services and Content
        (as defined later in this agreement). Vmood may terminate this license at any time for any reason or for no
        reason.
      </p>
      <p className='p6'><strong>Vmood account</strong></p>
      <p className='p7'>Creating an Account through registration is not currently required. Vmood, at its sole
        discretion, requires the creation of an Account for all or certain future Services. Vmood will notify You of
        those changes if and when they happen. In such event, the following terms will apply.
      </p>
      <p className='p7'>You may be required to create an account with Vmood to use certain Services with username and
        password or an Access Code (&ldquo;Account&rdquo;). Your Account will provide You with access to Services and
        functionality that We may set up, modify and maintain from time to time and at our sole discretion.
      </p>
      <p className='p7'>When you create Your Account, if required, You must provide accurate and complete Information.
        If You provide any Information that is untrue, inaccurate, out-of-date or incomplete (or become untrue,
        inaccurate, out-of-date or incomplete), or if We have reasonable grounds to suspect that the Information is
        false or inaccurate, not updated or incomplete, We reserve the right to suspend or terminate Your Account and
        refuse any and all current or future use of the Service (or any portion thereof).
      </p>
      <p className='p7'>You are solely responsible for the activity that occurs on your Account and You are required to
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        keep your Account password secure. Whenever you need to set a password, We encourage you to use a &ldquo;strong"
        password (passwords use a combination of upper and lower case letters, numbers, and symbols) for Your Account.
        You must notify Vmood immediately of any security breach, unauthorized access to Your chats or other data, or
        unauthorized use of Your Account. Vmood will not be responsible for any losses resulting from the unauthorized
        use of Your Account.
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p7'>You may never use another User's Account.</p>
      <p className='p7'>You can control Your User Account profile and how You interact with the Services by changing
        Settings in the Mobile Software. You agree to our use of Your profile to send You email notifications regarding
        the Service, include any notice required by law in lieu of being communicated by mail.
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p6'><strong>Vmood SPECIALIST'S SERVICES</strong></p>
      <p className='p8'>[
        <em>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          This section applies only to consultations with Vmood's specialist or Vmood's doctor. For
          some organized versions of the Vmood App, the Vmood App may provide a link to other support from a medical
          organization or provider, in that case, their Terms and Privacy Policy will apply.
        </em>]
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p8'>When You use &ldquo;Mental health services through Vmood "or" Vmood consultant
        services&rdquo; provided to You through the Vmood Application, The following terms and conditions apply:
      </p>
      <p className='p8'>1. Services provided by professionals are Premium Services provided by Vmood Apps;</p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p8'>2. You choose to use Vmood's Expert Services via the Vmood App by selecting and subscribing to a
        consulting package offered;
      </p>
      <p className='p8'>3. Please do not share Your Personal data (such as full name, date of birth, gender, contact
        number, address, financial identifier, government provided identifier) or your medical-related data or any other
        sensitive data (such as Your sexual orientation, religious or political opinions, financial data) when You use
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Vmood's Service.
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p9'>4. You may access Vmood's Specialist Services and Therapeutic Services by providing a nickname
        or a username that is not personally identifiable. You are responsible for the conduct, postings and
        transmissions made under Your User name;
      </p>
      <p className='p9'>5. Vmood Specialist Services or Vmood Therapist Services are provided by highly trained and
        qualified mental health professionals. By using Specialist Services or Vmood Therapist Services You understand
        and agree that the designated specialist to work with You will likely be located remotely and possibly not in
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        your country or region of residence. Vmood's experts are fully qualified and licensed by competent authorities.
        Please note, however, that they may not be licensed to practice in Your country or geographic region;
      </p>
      <p className='p8'>6. Expert services will use text messages or phone calls for advice and support to help you move
        towards healthy goals. Services will focus on developing an emotional health and resilience plan. The basic
        principle of Expert Services is that You have the knowledge and ability to make the desired changes in Your
        life. The role that an expert will assist you in finding your own path, helps You to exploit Your own strengths
        and abilities, so that You can identify and use the resources around You to fill any gaps;
      </p>
      <p className='p8'>7. Services support therapy through games, instructional videos. To build these documents, the
        expert team is also based on these principles of cognitive therapy (CBT) to help You take control of Your mental
        and emotional state. Vmood experts will rely on various evidence-based techniques to provide empathy and
        non-judgmental support. The professionals will listen empathically to your concerns, promote positivity, and
        support you in successful lifestyle changes, so You can better manage your situation and build emotional
        resilience;
      </p>
      <p className='p8'>8. The Vmood app allows you to connect and have scheduled therapy sessions based on scheduling
        with a Vmood specialist. You understand and agree that the Services provided are for the purpose of listening to
        You, assisting You in achieving Your defined goals and pointing You to the appropriate Vmood Application
        tools;
      </p>
      <p className='p8'>9. You understand and agree that Vmood Mental Health Services or Vmood Specialist Services is
        not a substitute for face-to-face and psychotherapy / or does not give a diagnosis, prognosis, treatment or cure
        / condition / disorder, and / or fails to provide any type of state-controlled mental health service in Your
        country of residence;
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p8'>11. Vmood's Service is not an Emergency or Crisis Service. If at any time you have an emergency,
        medical or other, please contact the emergency contacts in your local area immediately;
      </p>
      <p className='p8'>12. Through the application Vmood will assign an expert to You when You sign up for the Service
        or when you schedule your first session; www.vmood.org
      </p>
      <p className='p8'>13. You can also switch your specialist at any time after your first 4 sessions by writing to
        Vmood at: vmood@phad.org ;
      </p>
      <p className='p8'>14. Sessions are scheduled according to a convenient schedule for both clients and Vmood
        Therapists;
      </p>
      <p className='p8'>15. It is your responsibility to schedule a session with your assigned Vmood expert to agree on
        a schedule;
      </p>
      <p className='p8'>16. In addition to a scheduled session, You can send messages and chat with a Vmood healthcare
        professional and the specialist will try to respond within 24 hours. Messaging will be with text-based chat only
        and through the Vmood Apps only. You agree not to collect contact details or any other personal information, or
        to contact a Specialist outside of Vmood Apps;
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p8'>17. You can cancel or reschedule a scheduled counseling session any time before 3 o'clock to
        start a scheduled session. Cancellation or rescheduling is not allowed within 3 hours or after the session has
        started;
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p8'>18. You can have Your private chats with a Vmood expert at any time in Vmood App. You'll be able
        to access your chats and session summaries with Experts even after your subscription ends;
      </p>
      <p className='p8'>19. Your Vmood therapist intends to provide Support Services to achieve Your goals. Based on the
        Information You provide and the specifics of Your condition, the expert will make recommendations to You. Users,
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        and experts are partners in the support process. You have the right to agree or disagree with the expert's
        recommendations;
      </p>
      <p className='p8'>20. You agree to the private messaging protocol with your Vmood expert or and post the message
        according to the User Content section of this agreement;
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p8'>21. Vmood application acts as a "connector" for you to access online health care content of
        Vmood or Vmood Expert. You agree that You are using the Services to your best reviews and we will make claims or
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        warranties based on scientific evidence about (a) Vmood Apps accuracy or availability, (b) an Expert's
        willingness or ability to give advice or self-practice therapies, (c) the willingness or ability of any Vmood
        Healthcare Coach or Vmood Therapist for advice, or (d) whether the advice of your Vmood health professional or
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Vmood Therapist is right for You or anyone else's;
      </p>
      <p className='p8'>22. Vmood does the reference and background check of all Vmood Experts. You can view all
        profiles of Our Vmood Therapists;
      </p>
      <p className='p8'>23. You understand and agree that, although Vmood Specialists may have been accessed through the
        Vmood App, but <strong>we</strong> cannot predict or rate competence, or suit your needs, of the expert. You
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        further acknowledge and agree that You are solely responsible for Vmood's decision to approach a Professional;
      </p>
      <p className='p8'>24. The monthly membership fee for Vmood Healthcare Service or Vmood Expert Services will be
        mentioned on the Vmood App and assigned to You at the time of purchase (&ldquo;Fees&rdquo;). We may amend the
        Fees at its sole discretion. Any changes to the Fees will only be effective upon your consent through the Mobile
        Software. We reserve the right to discontinue the Service when You do not pay the Fee. Fees are paid through
        other payment mechanisms referred to by us at our sole discretion from time to time, before the time the
        Services are provided;
      </p>
      <p className='p8'>25. Since expert consultation is not a medical treatment, insurance generally will not pay for
        the cost;
      </p>
      <p className='p8'>26. Your Subscription will be renewed automatically until Your Subscription is canceled.
        Cancellation is solely your responsibility, which can be done at any time from Google Play and Apple iTunes;
      </p>
      <p className='p8'>27. Your cancellation depends on Your payment method. Here are two ways to cancel:</p>
      <p className='p8'>Google Play:</p>
      <p className='p8'>iTunes: https:</p>
      <p className='p8'>28. Fee that the User must pay is a fixed fee and so, You can use Vmood Health Promotion
        Services or Vmood Specialist Services as you like, But if requested, you will have to pay a membership fee;
      </p>
      <p className='p8'>29. It is your responsibility to record the date and time that the subscription will expire or
        to the renewal and cancellation date before Your payment will be processed. Payments may be subject to
        prevailing exchange rates;
      </p>
      <p className='p8'>30. Vmood apps may offer in-app promotions from time to time like free trials, discount or
        upgrade to a package for a limited time. You understand and agree that such promotions are only available once
        and cannot be renewed or transferable for multiple Users;
      </p>
      <p className='p8'>31. The Vmood Application may ask You to provide some Personally Identifiable Information (such
        as your full name, email, phone number, address, gender and other identifiable information including Sensitive
        Information such as Your Medical Information or religious opinion). Any Information shared by You will be
        handled in accordance with the Privacy Policy;
      </p>
      <p className='p8'>32. You have rights and these can be viewed in the Privacy Policy. If You have any questions, or
        complaints, please contact us at <span className='s4'>vmood@phad.org;</span>
      </p>
      <p className='p8'>33. We use the statistical and research purposes of the encrypted data to improve Our Products
        and Services and contribute to the development of user-centered best practices for global mental health
        promotion. Please refer to our Privacy Policy regarding how we handle Your data;
      </p>
      <p className='p8'>34. We reserve the right to modify or discontinue, temporary or permanent, any Service within or
        on the Vmood Application, with or without notice to Users. User agrees that we will not be responsible to the
        User or any third party for any loss or damage or injury which may result in the User or any third party due to
        the suspension or interrupt of such Service. Tuy nhi&ecirc;n, nếu Dịch vụ bị ngừng tạm thời hoặc ngừng cung cấp
        vĩnh viễn, ch&uacute;ng t&ocirc;i sẽ bắt đầu ho&agrave;n lại Ph&iacute; cho Người d&ugrave;ng đ&atilde; thanh
        to&aacute;n tr&ecirc;n cơ sở xem x&eacute;t bất kỳ phần n&agrave;o chưa sử dụng của Dịch vụ. Such refund will be
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        subject to Vmood's refund policy and terms and conditions (whichever applies). In such case, You must contact us
        for such requests. You will need to send an email request with your reasons to
        <span className='s4'>vmood@phad.org</span> We will deal with any such request case by case;
      </p>
      <p className='p8'>35. All communications and data obtained in the course of using the Service are confidential.
        Storage, use and security of Information are governed by the Privacy Policy;
      </p>
      <p className='p8'>36. You agree and allow Vmood to collect and process Your data in accordance with the terms of
        our Privacy Policy;
      </p>
      <p className='p8'>37. A VMOOD SERVICE OR A VMOOD SPECIALIST CAN DISCOVER YOUR DATA IF VMOOD REASONABLE TRUST THAT
        DISCLOSURE IS NECESSARY AND MUST COMPLY WITH LEGAL PROCESS (IT IS A LEGAL ORDER), OTHER LEGAL SEARCH REQUIRES
        ANY GOVERNMENT AUTHORIZATION;
      </p>
      <p className='p8'>40. You understand that the use of technology is not always safe and that You accept technology
        risks while using Your mobile device. You will adhere to security practices in the process of using digital,
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        including but not limited to setting a PIN code to protect access, don't share this code with anyone, and don't
        let your phone or chats unlock for others to see. You understand and agree that the safe use is Your
        responsibility, We will not be liable in any case arising from unauthorized access to Your chats or other
        data;
      </p>
      <p className='p8'>41. We and You mutually acknowledge that they may hold discussions based on data and information
        provided by You. We will not voluntarily disclose your data to third parties. Any use of data disclosed by You
        to an expert will be subject to the Privacy Policy;
      </p>
      <p className='p8'>42. To honor and protect the intellectual property of Vmood, You expressly agree not to disclose
        or transmit any proprietary Information about Vmood, material or methodology for any third party. The
        obligations under these Terms remain in effect after the Vmood Service or Your relationship with Vmood
        terminates;
      </p>
      <p className='p6'><strong>RULES OF SERVICE</strong></p>
      <p className='p8'>You agree not to participate in any prohibited activities aimed at Vmood.</p>
      <p className='p8'>We may change the Service without prior notice; cease providing the Services or its features to
        You or to Users at large; or create a limit of use for the Service. We may permanently or temporarily terminate
        or suspend Your access to the Services without notice and liability for any reason, include if at Our sole
        discretion, You violate any of the terms of the Agreement or for no reason. After termination for any reason or
        for no reason, You continue to be bound by the Agreement. You are responsible for all mobile data uses resulting
        from your use of the Service. You are solely responsible for Your interactions with other Vmood Users. We have
        the right, but not the obligation, to monitor disputes between You and other Users. Vmood will not be liable for
        Your interactions with other Users, or for any actions or inaction by Users.
      </p>
      <p className='p10'><strong>CONTENT USER </strong></p>
      <p className='p8'>Some areas of the Service may, now or in the future, allow Users to post content such as chat
        content, Profile info, photos, videos, comments, questions and content or Other Information [any such material
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        that a User submits, posts, displays or otherwise makes available on the Service ("User Content ")]. We declare
        no ownership of User Content created by You; The User Content You create is still Yours. However, by sharing
        User Content through the Service, You agree to allow others (follow your settings and this Agreement) view, edit
        and / or share Your User Content. Vmood reserves the right (but no obligation) at its sole discretion to remove
        any User Content shared via the Service.
      </p>
      <p className='p8'>You agree not to post or transmit User Content that affects Vmood or is prohibited by law or
        not <span className='s1'>allowed</span>. <span className='s1'>You </span>agree that any User Content You have
        posted, does not and will not infringe the rights of third parties in any way, including but not limited to any
        Intellectual Property Rights (as defined below) or privacy. Vmood reserves the right, but has no obligation, to
        refuse and / or remove any User Content that Vmood believes, in its sole discretion, to violate these
        regulations.
      </p>
      <p className='p10'><strong>LICENSE TO USER CONTENT</strong></p>
      <p className='p8'>By posting any User Content on the Service, for use in connection with the Service for which You
        expressly license, and You represent and warrant that You have all necessary rights to grant, grant Vmood a
        royalty-free, sublicensable, transferable right, perpetual, irrevocable, no - exclusive worldwide license for
        use, reproduction, modification, publication, lists Information relating to, editing, translating, distributing,
        making available, publicly displayed, and making derivative works of all such User Content and Names, Your voice
        and / or as in Your User Content, in whole or in part, and in any form, medium or technology, whether now known
        or later developed in accordance with the Privacy Policy.
      </p>
      <p className='p10'><strong>USE OF USER CONTENT</strong></p>
      <p className='p8'>All data includes User Content where applicable are anonymous and encrypted prior to use for
        research purposes. This study examines how different activities on Mobile Software impact the effectiveness of
        measures aimed at helping customers. Vmood may use User Content and other data collected from Users pursuant to
        this Agreement for this research purpose. Research and results are key to helping Users make real change and
        progress in their lives, and we appreciate the support You provide for this mission by sharing Your data.
      </p>
      <p className='p10'><strong>MOBILE SOFTWARE</strong></p>
      <p className='p8'>We provide the available Mobile Software to access the Service via a mobile device. To use the
        Mobile Software, You must have a compatible mobile device with the Mobile Software. Vmood does not guarantee
        that the Mobile Software will be compatible with Your mobile device. Vmood hereby grants You a non-exclusive,
        non-transferable, revocable license to use a compiled copy of the Mobile Software for a Vmood Account solely
        owned or given by You or rental, for Your personal use. You agree to automatic upgrades on Your mobile device
        and agree that this Agreement will apply to all such upgrades. Any third-party code that may be included in the
        Mobile Software is subject to the applicable open source or third-party license End-user license agreement, if
        applicable, that authorizes use of such code. The foregoing license is not a sale of the Mobile Software or any
        copy thereof and Vmood or its third-party partners or suppliers retain all rights, title, and interest. Mobile
        Software (and any copies thereof). Any attempt by You to transfer any of the rights, obligations or obligations
        hereunder, except as expressly provided in this Agreement, is void. Vmood reserves all rights not expressly
        granted under this Agreement.
      </p>
      <p className='p10'><strong>OUR EQUITY</strong></p>
      <p className='p8'>Except for Your User Content, the Services and all materials contained or transmitted therein,
        including but not limited to, software, images, text, graphics, illustrations, trademarks, service marks,
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        copyrights, photos, audio, video, music, and User Content belong to other Users ("Vmood's Content"), and all
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Intellectual Property Rights relating to such content, which are the exclusive property of Vmood and Vmood's
        licensors (including other Users who post User Content on the Service). Use of Vmood Content for any purpose not
        expressly permitted by this Agreement is strictly prohibited.
      </p>
      <p className='p8'>You can choose or We may invite You to submit comments or ideas about the Services, including
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        but not limited to how to improve our Services or products ("Ideas "). By submitting any Ideas, You agree that
        Your disclosure is gratuitous, unsolicited and without limitations and will not place Vmood under any other
        trustee or obligation, and that, We may freely use the Ideas without paying any additional compensation to You
        and / or disclose the Idea on a non-confidential basis or to anyone. You further acknowledge that, by accepting
        Your submission, Vmood does not waive any right to use similar or related ideas that Vmood had previously known,
        or developed by, employees of it, either from sources other than You.
      </p>
      <p className='p10'><strong>PROFESSIONAL ADVICE</strong></p>
      <p className='p8'>Mental health information provision services including suggested tools and techniques (For
        example: Yoga or therapeutic activities or therapeutic exercises), The Information is for informational purposes
        only and is construed to be professional advice. Consideration should be given to taking official actions and
        instructions based on the Information contained in the Vmood Service. You may also seek independent professional
        advice from a licensed and / or qualified person in the area of application prior to use.
      </p>
      <p className='p10'><strong>PRIVACY</strong></p>
      <p className='p8'>We care deeply about the privacy of our Users. You understand that by using the Services, You
        agree to the collection, use and disclosure of Your Personal data and aggregate data as set forth in our Privacy
        Policy and to data storage. Your account is collected, used, transferred and processed by Us in accordance with
        our Privacy Policy.
      </p>
      <p className='p10'><strong>PROTECTION</strong></p>
      <p className='p8'>We care deeply about the integrity and security of Your Personal data and maintain the security
        measures required by applicable law in Vietnam and globally to ensure that data is allowed to be used. However,
        we cannot guarantee that unauthorized third parties will never be able to defeat our security measures or use
        Your Personal data for improper purposes. You acknowledge that You provide your Personal data at your own
        risk.
      </p>
      <p className='p10'><strong>THIRD PARTY LINKS</strong></p>
      <p className='p8'>The Service may contain links or other content related to the websites, advertisers, third party
        products and / or services, special offers or other events or activities offered by the third party are not
        owned or controlled by Vmood. If You access third party websites from the Service, You do so at Your own risk
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        and You understand that this Agreement and Vmood's Privacy Policy do not apply to Your use of such websites. You
        waive Vmood from any and all liability, directly or indirectly, arising out of Your use of any third-party
        website, service or content;
      </p>
      <p className='p10'><strong>MONITOR WEBSITE, MOBILE SOFTWARE, AND USER CONTENT </strong></p>
      <p className='p8'>We reserve the right and freedom to monitor the content on the Website and Mobile Software at
        all times including Information provided in Your Account or any User Content posted by You. Monitoring of the
        Website is important for determining the truthfulness of the Information provided by You, and all Users remain
        subject to the Terms and Conditions provided herein. Under the Agreement, We will also have the right to remove
        any objectionable content, including User Content, that is contrary to the Agreement or to share such
        Information with any government agency in accordance with the process of the current law in Vietnam. Hereby,
        Vmood has the right but no obligation to monitor the content on the Website and Mobile Software. Accordingly,
        Vmood will not be liable for any claims, losses, damages or expenses that may arise as a result of User Content
        published by You or any other User on the Website.
      </p>
      <p className='p10'><strong>PAYMENT</strong></p>
      <p className='p8'>[Vmood App may provide links for support from the service, in which case their terms and Privacy
        Policy will prevail.]
      </p>
      <p className='p8'>The service is provided free of charge to Existing Users. User can choose to use additional
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        services available on the Mobile Software ("Premium Services") when paying subscription fee. Premium Services
        will be considered part of the Service for the purposes of the Agreement. We may modify the membership fee for
        Premium Services or subject all Services to a certain subscription fee at our sole discretion. User will be
        notified of the applicable fees payable for a Premium Service and Service prior to User using such service.
      </p>
      <p className='p8'>The subscription will automatically renew until the subscription is canceled before the end of
        the current period. User is fully responsible for canceling, can do it directly at any time from Vmood or phone
        to notify &hellip;&hellip;.. WE DO NOT ACCEPT CONDITIONS TO CANCEL REGISTRATION VIA EMAIL;
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p8'>It is the User's responsibility to note that the date and time of the subscription will expire
        or until the renewal and cancellation date before Your payment will be processed. Payments may be subject to
        prevailing exchange rates.
      </p>
      <p className='p8'>Your cancellation depends on Your payment method. Here are two ways to
        cancel: &hellip;&hellip;..
      </p>
      <p className='p10'><strong>INDEMNIFY</strong></p>
      <p className='p8'>You agree to protect, indemnify and keep Vmood harmless and against all claims, damages,
        liabilities, losses, liabilities and expenses (including but not limited to attorney fees) arising from: (i)
        Your use and access to the Services, including any data or content that You transmit or receive; (ii) You are in
        breach of any of the terms of this Agreement, including but not limited to Your breach of any of the above
        statements; (iii) Your violation of any third party rights, including but not limited to any privacy or
        Intellectual Property Rights; (iv) You violate any applicable law, rule or regulation; (v) any claim or damage
        arising from any of Your User Content or any content submitted through Your account; or (vi) any other party
        accessing and using the Services using Your username, PIN, unique password or other appropriate security
        code.
      </p>
      <p className='p11'><strong>GOVERNING LAW AND JURISDICTION</strong></p>
      <p className='p12'>These Terms and Conditions and any dispute or claim arising out of or relating to these Terms
        and Conditions shall be construed and governed by the laws of Vietnam.
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p12'>Any dispute, claim or dispute arising in connection with the Customer's use of Vmood Platform
        will be resolved firstly by means of good faith negotiation. Where the parties fail to reach agreement on
        dispute settlement then that dispute will be resolved by arbitration at the Vietnam International Arbitration
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Center (VIAC) beside the Vietnam Chamber of Commerce and Industry under VIAC's Rules of Arbitration; The number
        of arbitrators is one, is indicated according to VIAC rules and the language of arbitration is Vietnamese.
      </p>
      <p className='p13'><strong>NOTICE PROCEDURE AND CHANGES TO AGREEMENT.</strong></p>
      <p className='p8'>Vmood may provide notices, whether they are required by law or for other marketing or business
        purposes, to You by email, written or printed notice, or through posting such notice on our website, as defined
        by Vmood. Vmood reserves the right to determine the form and means of providing notices to Users, provided You
        can opt out of certain notification methods as described in this Agreement. Vmood is not responsible for any
        automatic filters You or Your network provider may apply to email notifications that we send to the email
        address you give us. Vmood may, at its sole discretion, modify or update this Agreement from time to time, and
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        therefore You should review this page periodically. When We change the Agreement in an important way, We'll
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        update the 'last modified' date at the top of this page. Your continued use of the Service following any such
        change constitutes Your acceptance of the new Terms and Conditions. If You do not agree to any of these terms or
        any future Terms and Conditions, do not use or continue to access the Service.
      </p>
      <p className='p10'><strong>ENTIRE AGREEMENT / EFFECT FOR EACH PART.</strong></p>
      <p className='p8'>This Agreement and Terms, together with any amendments, policies and any additional agreements
        You may enter into with Vmood in connection with the Services, will constitute the entire agreement between You
        and Vmood regarding the Service. If any provision of this Agreement is deemed invalid by a court of competent
        jurisdiction, then the invalidity of that term will not affect the validity of the remaining terms of this
        Agreement, will remain in full force and effect.
      </p>
      <p className='p14'><strong>COMMENTS, COMPLAINTS AND CONTACTS</strong></p>
      <p className='p12'>We encourage all Customer comments to improve Vmood Technology Platform and our services. Any
        suggestions, complaints (if any) please send us in one of the following forms:
      </p>
      <p className='p12'>Through hotline:</p>
      <p className='p12'>Via email (email): <span className='s3'>vmood@phad.org</span></p>
      <p className='p12'>Directly at the company office: 14th floor, Icon4 building, 1-3 Cau Giay, Dong Da, Hanoi or
        send mail to.
      </p>
      <p className='p10'><strong>=====================================</strong></p>
      <p className='p15'><strong>Institute for Population, Health and Development (PHAD)</strong></p>
      <p className='p8'><strong>Headquarters</strong>: No. 18, Lane 132, Hoa Bang, Cau Giay, Hanoi<br />
        <strong>Executive office / mailing address</strong>: Level 14, Icon4 Building, No. 1-3 Cau Giay, Dong Da, Hanoi<br />
        <strong>Phone</strong>: (+84) 2473 000 988
      </p>
    </div>
  );
}

export default English;