import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import en from "../lang/en.json";
import vn from "../lang/vn.json";
import esp from "../lang/esp.json";
import {getLanguageSetUp} from "../repositories/localStorage/get";
// import {checkIp, convertCountryCode} from "../repositories/LocalStorage/checkCountry";

// const initialState = getLanguageSetUp();
//
// async function resetLang() {
//     if (!initialState) {
//         await i18n.changeLanguage(convertCountryCode(await checkIp()));
//     }
// }

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
      lng: getLanguageSetUp() || 'en',
      resources: {
        en: {
          translations: en,
        },
        vn: {
          translations: vn,
        },
        esp: {
          translations: esp,
        },
      },
      fallbackLng: "en",
      debug: true,
      ns: ["translations"],
      defaultNS: "translations",
      keySeparator: ".",
      interpolation: {
        escapeValue: false,
        formatSeparator: ",",
      },
      react: {
        wait: true,
      },
    });

// resetLang();

export default i18n;
