import React from 'react';
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import Logo from "../../assets/images/logo-footer.svg";
import IconMarker from "../../assets/images/icons/icon-marker.svg";
import IconPhone from "../../assets/images/icons/icon-phone.svg";
import IconLetter from "../../assets/images/icons/icon-letter.svg";
import './footer.css';


function Footer() {
  const {t} = useTranslation();
  return (
    <footer>
      <div className='container'>
        <div className='row m-0'>
          <div className='col-4 col-lg-2 p-0 first-col'>
            <Link className='m-0' to='/home'>
              <img src={Logo} className='logo-footer' alt='loading' />
            </Link>
            <p>2020 VMood, Inc.</p>
          </div>
          <div className='col-8 col-lg-4 p-0 block-contact'>
            <h2>{t('language.info_contact')}</h2>
            <div className='under-line' />
            <p className='centered-vertical contact'><img src={IconMarker} alt='loading' />{t('contact.address1')}</p>
            <p className='centered-vertical contact'><img src={IconMarker} alt='loading' />{t('contact.address2')}</p>
            <p className='centered-vertical contact'><img src={IconPhone} alt='loading' />(+84) 2473 000 988</p>
            {/*<p className='centered-vertical contact'><img src={IconLetter} alt='loading' />info@vmood.com</p>*/}
          </div>
          <div className='col-8 col-lg-4 p-0 block-fast-contact'>
            <h2>{t('language.fast_contact')}</h2>
            <div className='under-line' />
            {/*<a href='/introduce'>{t('language.about_vmood')}</a>*/}
            <Link to='/questions-answers'>{t('language.faq')}</Link>
            {/*<a href=' '>{t('language.contact_admin')}</a>*/}
          </div>
          <div className='col-4 col-lg-2 p-0 last-col'>
            <Link to='/terms-of-use'>{t('language.terms_of_use')}</Link>
            <Link to='/policy'>{t('language.privacy_policy')}</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
