import axiosClient from "./axiosClient";

const informationAPI = {
  getInformation: (accessToken) => {
    const url = '/experts/profile';
    return axiosClient.get(url,{
      headers: {
        "Access-Token": accessToken
      },
      validateStatus (status) {
        return status;
      }
    });
  },
  updateInformation: (accessToken, params) => {
    const url = '/experts/update-profile' ;
    return axiosClient.patch(url, params, {
      headers: {
        "Access-Token": accessToken
      },
      validateStatus (status) {
        return status;
      }
    });
  },
};
export default informationAPI;