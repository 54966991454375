import React, {lazy, Suspense} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {useSelector} from "react-redux";
import {BoxLoading} from 'react-loadingg';
import COLORS from "../../config/constants/color";
import componentLoader from '../loader'

function Public(props) {
  const {component, layout, auth, ...rest} = props;
  const Component = lazy(() =>
    componentLoader(() =>
        import(`../../containers/${component}`)
      , 10)
  );

  const authLogin = useSelector(state => state.auth);
  const authFalseAccept = (url) => {
    return (
      <Route
        {...rest}
        render={() => (
          <Redirect
            to={{
              pathname: url,
              state: {from: props.location.pathname},
            }}
          />
        )}
      />
    );
  };

  if (authLogin.authentic && auth === false) {
    return authFalseAccept('/home');
  }
  return (
    <Route
      {...rest}
      render={() => (
        <Suspense
          fallback={<div className='min-height-600'><BoxLoading size='large' color={COLORS.LOADING_COMPONENT} /></div>}
        >
          <Component {...props} />
        </Suspense>
      )}
    />
  );
}

export default Public;