import React from 'react';
import "./termsOfUser.css"

function Vietnamese() {
  return (
    <div className='parent'>
      <p className='title text-color-primary'><strong>CÁC ĐIỀU KHOẢN VÀ ĐIỀU KIỆN SỬ DỤNG DỊCH VỤ</strong></p>
      <p className='p2'>Ngày có hiệu lực ban đầu: 11/2020</p>
      <p className='p2'>Ngày sửa đổi mới nhất:</p>
      <p className='p2'>Phiên bản: 1.0.0</p>
      <p className='p3' />
      <p className='p4'>Chào mừng bạn đến với Vmood, dịch vụ di động và trực tuyến của chúng tôi (sau đây được gọi là
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        "Vmood", hoặc "Chúng tôi", hoặc "Của chúng tôi").
      </p>
      {/* eslint-disable-next-line react/no-unescaped-entities */}
      <p className='p4'>Tên miền www.vmood.org (sau đây gọi là "<strong>Trang web</strong>") và ứng dụng di động Vmood
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        ("<strong>Phần mềm di động</strong>" hoặc "<strong>Ứng dụng Vmood</strong>") thuộc sở hữu và quản lý của Viện
        Dân số, Sức khỏe và Phát triển (PHAD), sau đây gọi tắt là <strong>“Chúng tôi”</strong> hoặc
        “<strong>Vmood</strong>”, một Viện được thành lập và hoạt động theo luật pháp nhà nước Cộng hòa Xã hội Chủ nghĩa
        Việt Nam, <span className='s1'>có số đăng ký hoạt động khoa học</span>, có trụ sở chính tại số nhà 18, ngõ 132,
        Hoa Bằng, Cầu giấy, Hà nội và văn phòng điều hành tại tầng 14, tòa nhà ICON4, số 1-3 đường Cầu Giấy, Đống Đa, Hà
        Nội.
      </p>
      <p className='p4'>Điều khoản Dịch vụ này áp dụng cho tất cả khách truy cập, chủ thể dữ liệu và những người khác
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        truy cập Dịch vụ <strong>("(Các) người dùng</strong>" hoặc "<strong>Bạn/các bạn</strong>" hoặc <strong>“Khách hàng”</strong>). Trang này giải thích các điều khoản mà Bạn có thể sử dụng Trang web của Chúng tôi, các trang
        web khác do Chúng tôi duy trì liên quan đến Ứng dụng của chúng tôi, Việc Bạn sử dụng “<strong>Ứng dụng Vmood</strong>”), dịch vụ chuyên gia được cung cấp bởi Bác sỹ/Chuyên gia tâm lý/Huấn luyện viên của Vmood (sau
        đây gọi là “<strong>Dịch vụ chuyên gia</strong>”) và/hoặc “<strong>Dịch vụ trị liệu</strong>” của Vmood được
        cung cấp qua Ứng dụng Vmood (gọi chung là <strong>“(các) Dịch vụ”</strong>). Bằng cách truy cập hoặc sử dụng
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Dịch vụ, Bạn cho biết rằng Bạn đã đọc, hiểu và đồng ý bị ràng buộc bởi điều khoản dịch vụ này ("
        <strong>Điều
          khoản và Điều kiện
          {/* eslint-disable-next-line react/no-unescaped-entities */}
        </strong>") và việc thu thập và sử dụng Thông tin của Bạn như được quy định trong Chính sách
        Bảo mật và các điều khoản của bất kỳ chính sách nào khác của Vmood có thể áp dụng cho Bạn cho dù Bạn có phải là
        Người dùng đã đăng ký Dịch vụ của Chúng tôi hay không. Điều khoản và Điều kiện, Chính sách Quyền riêng tư này và
        bất kỳ điều khoản và điều kiện nào khác có thể áp dụng sau đây sẽ được gọi chung là “<strong>Thỏa thuận</strong>”
        hoặc “<strong>Hợp đồng</strong>”. Để biết thêm thông tin liên quan đến việc sử dụng giữ liệu của bạn trong
        Vmood, vui lòng đọc Chính sách Bảo mật của Chúng tôi.
      </p>
      <p className='p5'><strong>LƯU Ý: </strong></p>
      <p className='p4'>1. Nếu Bạn đang có ý định tự tử hoặc dự định thực hiện ý nghĩ tự sát, hoặc nếu Bạn cảm thấy rằng
        Bạn có thể gây nguy hiểm cho Chính bạn hoặc cho người khác, hoặc nếu Bạn có bất kỳ trường hợp khẩn cấp nào về
        sức khỏe tâm thần hoặc sức khỏe tâm thần nghiêm trọng, hoặc nếu Bạn đang gặp khủng hoảng hoặc chấn thương hoặc
        lạm dụng, vui lòng ngừng sử dụng Dịch vụ ngay lập tức và gọi số khẩn cấp có liên quan hoặc các dịch vụ y tế khẩn
        cấp.
      </p>
      <p className='p4'>2. Nếu Bạn dưới 18 tuổi, vui lòng đọc qua <span className='s1'>Chính sách Quyền riêng tư </span>của
        Vmood và <span className='s1'>Điều khoản Dịch vụ </span>của Vmood với cha mẹ hoặc người giám hộ hợp pháp của Bạn
        hoặc kiểm tra với Tổ chức của Bạn để hiểu tính đủ điều kiện trước khi sử dụng. Vmood phù hợp với người từ đủ 18
        tuổi đến đủ 60 tuổi và không được sử dụng bởi trẻ em dưới 13 tuổi.
      </p>
      <p className='p4'>3. Vui lòng không chia sẻ dữ liệu Cá nhân của Bạn (chẳng hạn như tên đầy đủ, ngày sinh, giới
        tính, số liên lạc, địa chỉ, số nhận dạng tài chính, số nhận dạng do chính phủ cung cấp) hoặc dữ liệu liên quan
        đến y tế của bạn hoặc bất kỳ dữ liệu nhạy cảm nào khác (chẳng hạn như sở thích, ý kiến tôn giáo hoặc chính trị,
        dữ liệu tài chính …) khi Bạn sử dụng <span className='s1'>Ứng dụng và Dịch vụ </span>Vmood.
      </p>
      <p className='p4'>4. Bạn hiểu rằng tương tác của Bạn với Vmood là thông qua App và được kết nối với một đội ngũ
        các chuyên gia. Chúng tôi cũng cung cấp những
        <span className='s1'>hướng dẫn các kỹ năng kiểm soát trầm cảm
        </span>, <span className='s1'>các trò chơi suy nghĩ tích cực và video giải quyết các vấn đề cảm xúc </span>với
        mục đích là cung cấp các công cụ và kỹ thuật dựa trên bằng chứng để quản lý cảm xúc và khuyến khích sức khỏe
        tinh thần trong <span className='s1'>bối cảnh tự vượt qua trầm cảm</span>.
      </p>
      <p className='p4'>5. Các dịch vụ của Vmood như <span className='s1'>tự phát hiện trầm cảm, luyện tư duy tích cực và giải quyết vấn đề </span>không
        nhằm mục đích thay thế cho liệu pháp tâm lý mặt đối mặt hoặc cung cấp chẩn đoán, tiên lượng, điều trị hoặc chữa
        khỏi bệnh/tình trạng/rối loạn hoặc cung cấp bất kỳ các dịch vụ sức khỏe tâm thần do nhà nước Việt Nam, Bộ Y tế
        quản lý. Đây là một phương thức hỗ trợ tạo điều kiện, chứ không phải là điều trị bệnh tật.
      </p>
      <p className='p4'>6. Bằng cách sử dụng Dịch vụ chuyên gia, bạn hiểu và đồng ý rằng họ được chỉ định làm việc với
        Bạn và sẽ được bố trí từ xa và có thể không ở gần nơi bạn sống hoặc tỉnh/thành phố của bạn.
      </p>
      <p className='p4'>7. Các dịch vụ của Vmood như <span className='s1'>tự sàng lọc trầm cảm, luyện tư duy tích cực, cách giải quyết vấn đề và dịch vụ liên quan được tích hợp trong phần mềm, do vậy </span>không
        thể và sẽ không đưa ra lời khuyên y tế hoặc lâm sàng. Trong trường hợp Bạn đề cập đến nhu cầu cần được tư vấn
        như vậy, có thể bạn sẽ được đề nghị tìm kiếm sự trợ giúp nâng cao về y tế hoặc <span className='s1'>gặp Bác sỹ tâm thần</span>.
      </p>
      <p className='p4'>8. Chúng tôi rất nỗ lực để giữ cho các cuộc trò chuyện của Bạn được riêng tư và an toàn như được
        nêu trong <span className='s1'>Chính sách Bảo mật</span>. Bạn cũng có thể bảo mật các cuộc trò chuyện và thiết
        bị của mình bằng cách làm theo các phương pháp hay nhất được nêu trong phần <span className='s1'>Chính sách bảo mật</span>.
      </p>
      <p className='p4'>9. Vì lý do an toàn và bảo mật, Chúng tôi đặc biệt khuyên Bạn nên giữ các cuộc trò chuyện của
        Bạn với Ứng dụng Vmood ở chế độ riêng tư. Chúng tôi đặc biệt khuyên Bạn nên đặt tự động cập nhật Ứng dụng Vmood
        trong cài đặt trình quản lý ứng dụng trên thiết bị di động của Bạn; để nhận các bản sửa lỗi và tính năng dựa
        trên Ứng dụng Vmood mới nhất. Luôn thoát khỏi phiên bản Ứng dụng Vmood trong thiết bị di động của bạn bằng cách
        sử dụng nút quay lại trước khi nâng cấp lên phiên bản mới hơn để tránh mất các cuộc trò chuyện đang diễn ra hoặc
        trước đó.
      </p>
      <p className='p5'><strong>Ứng dụng Vmood</strong></p>
      <p className='p4'>Ứng dụng Vmood là một ứng dụng hỗ trợ sức khỏe trên thiết bị điện tử nhằm hỗ trợ trầm cảm
        (“Vmood”) mà qua đó giúp khách hàng có thể tự sàng lọc phát hiện trầm cảm, kiểm soát và giới thiệu điều trị cho
        người trầm cảm tại Việt Nam, tùy theo lựa chọn của Bạn, khả năng đăng ký và gửi tin nhắn cho một chuyên gia sức
        khỏe tâm thần được đào tạo và có trình độ cao (“Huấn luyện viên” hoặc “Chuyên gia tâm lý” hoặc “Bác sỹ tâm
        thần”) để có thể sử dụng cơ chế hỗ trợ được tích hợp trong Ứng dụng Vmood và thông qua giao diện, có quyền truy
        cập vào các công cụ và kỹ thuật để quản lý tâm trạng của bạn.
      </p>
      <p className='p4'>Ứng dụng Vmood có sẵn cho cả hệ thống di động iOS và Android. Tương tác của bạn với một số các
        dịch vụ của VMood. Một số dịch vụ bị hạn chế về mục đích sử dụng.
      </p>
      <p className='p4'>Ứng dụng Vmood cung cấp và hỗ trợ khách hàng thông qua các công cụ và kỹ thuật
        <span className='s1'> dựa trên bằng chứng</span>để quản lý cảm xúc và khuyến khích sức khỏe tâm thần,
        <span className='s1'> như một công cụ can thiệp sớm</span>trong bối cảnh tự kiểm soát bản thân. Bạn đưa ra lựa chọn sử
        dụng một số dịch vụ như <span className='s1'>tự sàng lọc trầm cảm, luyện tập suy nghĩ tích cực và giải quyết vấn đề</span> dựa
        trên ước tính nhu cầu của riêng Bạn và đồng ý rằng điều này chỉ phù hợp cho việc tự kiểm soát ở mức cơ bản. Điều
        này không nhằm mục đích thay thế cho liệu pháp tâm lý đối mặt trực tiếp hoặc cung cấp chẩn đoán, tiên lượng,
        điều trị hoặc chữa khỏi bệnh/tình trạng/rối loạn. Sử dụng Ứng dụng Vmood, Bạn có thể theo dõi và quản lý tâm
        trạng của Bạn, đồng thời tìm hiểu các kỹ thuật dựa trên bằng chứng có thể giúp Bạn cảm thấy tốt hơn. Ứng dụng và
        Dịch vụ Vmood không nhằm mục đích sử dụng trong khủng hoảng như lạm dụng hoặc các tình trạng sức khỏe tâm thần
        phức tạp hoặc nghiêm trọng, chẳng hạn: có ý định tự tử, làm tổn hại đến bản thân và những người khác, hoặc cho
        bất kỳ trường hợp khẩn cấp y tế nào. Ứng dụng và Dịch vụ Vmood không thể và sẽ không đưa ra lời khuyên y tế hoặc
        lâm sàng. Nó chỉ có thể gợi ý rằng người dùng tìm kiếm trợ giúp nâng cao về y tế.
      </p>
      <p className='p5'><strong>Đủ điều kiện</strong></p>
      <p className='p4'>Bạn chỉ có thể Sử dụng Dịch vụ nếu Bạn đồng ý với Thỏa thuận này và hình thành hợp đồng ràng
        buộc với Vmood và chỉ tuân thủ tất cả các luật, quy tắc và quy định hiện hành của Pháp Luật Việt Nam. Nếu Bạn từ
        13 đến 18 tuổi, vui lòng đọc qua Chính sách quyền riêng tư của Vmood và Điều khoản dịch vụ của Vmood cùng với
        cha mẹ hoặc người giám hộ hợp pháp của Bạn và trong trường hợp đó, Thỏa thuận sẽ được coi là hợp đồng giữa PHAD
        và người giám hộ hợp pháp của Bạn, người giám hộ hoặc cha mẹ và trong phạm vi cho phép theo luật hiện hành, có
        thể thi hành đối với Bạn. Nghiêm cấm mọi người dưới 13 tuổi tạo tài khoản và/hoặc Sử dụng Dịch vụ.
      </p>
      <p className='p4'>Nếu Tổ chức của bạn chỉ định giới hạn độ tuổi khác, chẳng hạn như ít nhất 18 trở lên, làm điều
        kiện sử dụng Dịch vụ này, thì giới hạn đó sẽ được áp dụng thay vì giới hạn ở trên.
      </p>
      <p className='p4'>Vmood có quyền từ chối quyền truy cập vào Dịch vụ hoặc chấm dứt tài khoản của Bạn nếu không đáp
        ứng đủ điều kiện, theo quyết định riêng của mình. Dịch vụ không khả dụng cho bất kỳ Người dùng nào trước đây đã
        bị Vmood xóa khỏi Dịch vụ.
      </p>
      <p className='p5'><strong>Giấy phép Vmood</strong></p>
      <p className='p4'>Theo Điều khoản và Điều kiện, Bạn được cấp giấy phép không độc quyền, có giới hạn, không thể
        chuyển nhượng, có thể thu hồi tự do để sử dụng Dịch vụ chỉ cho mục đích sử dụng cá nhân của Bạn và khi được các
        tính năng của Dịch vụ cho phép. Vmood bảo lưu tất cả các quyền không được cấp rõ ràng ở đây trong Dịch vụ và Nội
        dung của Vmood (như được định nghĩa sau trong thỏa thuận này). Vmood có thể chấm dứt giấy phép này bất kỳ lúc
        nào vì bất kỳ lý do gì hoặc không có lý do.
      </p>
      <p className='p6'><strong>Tài khoản Vmood</strong></p>
      <p className='p7'>Tạo Tài khoản thông qua đăng ký hiện không bắt buộc. Vmood, theo quyết định riêng của mình, bắt
        buộc việc tạo Tài khoản đối với tất cả hoặc một số Dịch vụ nhất định trong tương lai. Vmood sẽ thông báo cho Bạn
        về những thay đổi đó nếu và khi chúng xảy ra. Trong trường hợp như vậy, các điều khoản sau đây sẽ được áp
        dụng.
      </p>
      <p className='p7'>Bạn có thể được yêu cầu tạo tài khoản với Vmood để sử dụng Dịch vụ nhất định bằng tên người dùng
        và mật khẩu hoặc Mã truy cập (“Tài khoản”). Tài khoản của Bạn sẽ cung cấp cho Bạn quyền truy cập vào các Dịch vụ
        và chức năng mà Chúng tôi có thể thiết lập, sửa đổi và duy trì theo thời gian và theo quyết định riêng của chúng
        tôi.
      </p>
      <p className='p7'>Khi tạo Tài khoản của Bạn, nếu được yêu cầu, Bạn phải cung cấp Thông tin chính xác và đầy đủ.
        Nếu Bạn cung cấp bất kỳ Thông tin nào không đúng sự thật, không chính xác, không cập nhật hoặc không đầy đủ
        (hoặc trở nên sai sự thật, không chính xác, không cập nhật hoặc không đầy đủ), hoặc nếu Chúng tôi có cơ sở hợp
        lý để nghi ngờ rằng Thông tin đó là sai sự thật, không chính xác, không cập nhật hoặc không đầy đủ, Chúng tôi có
        quyền đình chỉ hoặc chấm dứt Tài khoản của Bạn và từ chối bất kỳ và tất cả việc sử dụng Dịch vụ hiện tại hoặc
        trong tương lai (hoặc bất kỳ phần nào trong đó).
      </p>
      <p className='p7'>Bạn hoàn toàn chịu trách nhiệm về hoạt động xảy ra trên Tài khoản của mình và Bạn phải bảo mật
        mật khẩu Tài khoản của mình. Bất cứ khi nào Bạn cần đặt mật khẩu, Chúng tôi khuyến khích bạn sử dụng mật khẩu
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        "mạnh" (mật khẩu sử dụng kết hợp chữ hoa và chữ thường, số và ký hiệu) cho Tài khoản của Bạn. Bạn phải thông báo
        cho Vmood ngay lập tức về bất kỳ hành vi vi phạm bảo mật, truy cập trái phép vào các cuộc trò chuyện của Bạn
        hoặc dữ liệu khác, hoặc sử dụng trái phép Tài khoản của Bạn. Vmood sẽ không chịu trách nhiệm đối với bất kỳ tổn
        thất nào do việc sử dụng trái phép Tài khoản của Bạn.
      </p>
      <p className='p7'>Bạn không bao giờ được sử dụng Tài khoản của Người dùng khác.</p>
      <p className='p7'>Bạn có thể kiểm soát hồ sơ Tài khoản người dùng của Bạn và cách Bạn tương tác với Dịch vụ bằng
        cách thay đổi Cài đặt trong Phần mềm Di động. Bạn đồng ý với việc Chúng tôi sử dụng hồ sơ của Bạn để gửi cho Bạn
        các thông báo qua email liên quan đến Dịch vụ, bao gồm bất kỳ thông báo nào theo yêu cầu của pháp luật, thay cho
        việc liên lạc bằng thư bưu điện.
      </p>
      <p className='p6'><strong>Dịch vụ chuyên gia của Vmood</strong></p>
      <p className='p8'>[
        <em>
          Phần này chỉ áp dụng cho các cuộc tư vấn với chuyên gia của Vmood hoặc Bác sĩ của Vmood.
          Đối với một số phiên bản tổ chức của Ứng dụng Vmood, Ứng dụng Vmood có thể cung cấp liên kết đến hỗ trợ khác của
          tổ chức hoặc nhà cung cấp dịch vụ y tế, trong trường hợp đó, Điều khoản và Chính sách quyền riêng tư của họ sẽ
          được áp dụng.
        </em>]
      </p>
      <p className='p8'>Khi Bạn sử dụng “Dịch vụ chăm sóc sức khỏe tâm thần thông qua Vmood” hoặc “Dịch vụ chuyên gia tư
        vấn Vmood” được cung cấp cho Bạn thông qua Ứng dụng Vmood, các Điều khoản và điều kiện sau sẽ được áp dụng:
      </p>
      <p className='p8'>1. Dịch vụ cung cấp bởi các chuyên gia là Dịch vụ cấp cao được cung cấp bởi Ứng dụng Vmood;</p>
      <p className='p8'>2. Bạn chọn sử dụng Dịch vụ Chuyên gia của Vmood qua Ứng dụng Vmood bằng cách chọn và đăng ký
        một gói tư vấn được cung cấp;
      </p>
      <p className='p8'>3. Vui lòng không chia sẻ dữ liệu Cá nhân của Bạn (chẳng hạn như tên đầy đủ, ngày sinh, giới
        tính, số liên lạc, địa chỉ, số nhận dạng tài chính, số nhận dạng do chính phủ cung cấp) hoặc dữ liệu liên quan
        đến y tế của bạn hoặc bất kỳ dữ liệu nhạy cảm nào khác (chẳng hạn như xu hướng tình dục của Bạn, ý kiến tôn giáo
        hoặc chính trị, dữ liệu tài chính) khi Bạn sử dụng Dịch vụ của Vmood.
      </p>
      <p className='p9'>4. Bạn có thể truy cập Dịch vụ chuyên gia và Dịch vụ trị liệu của Vmood bằng cách cung cấp biệt
        hiệu hoặc tên người dùng không nhận dạng cá nhân. Bạn có trách nhiệm đối với hành vi, bài đăng và truyền tải
        được thực hiện dưới tên Người dùng của Bạn;
      </p>
      <p className='p9'>5. Dịch vụ chuyên gia Vmood hoặc Dịch vụ trị liệu Vmood được cung cấp bởi các chuyên gia chăm
        sóc sức khỏe tâm thần có trình độ và được đào tạo chuyên sâu. Bằng cách sử dụng Dịch vụ chuyên gia hoặc Dịch vụ
        Trị liệu Vmood Bạn hiểu và đồng ý rằng chuyên gia được chỉ định làm việc với Bạn sẽ có thể được bố trí từ xa và
        có thể không ở quốc gia hoặc vùng cư trú của Bạn. Các Chuyên gia của Vmood có đầy đủ trình độ và được cấp phép
        bởi cấp có thẩm quyền. Tuy nhiên, xin lưu ý rằng họ có thể không được cấp phép hành nghề ở quốc gia hoặc vùng
        địa lý của Bạn;
      </p>
      <p className='p8'>6. Dịch vụ chuyên gia sẽ sử dụng tin nhắn văn bản hoặc gọi điện thoại để tư vấn và hỗ trợ nhằm
        giúp Bạn hướng tới mục tiêu khỏe mạnh. Dịch vụ sẽ tập trung vào việc xây dựng kế hoạch nâng cao sức khỏe và khả
        năng phục hồi cảm xúc. Nguyên tắc cơ bản của Dịch vụ chuyên gia là Bạn có kiến thức và năng lực để thực hiện
        những thay đổi mong muốn trong cuộc sống của Bạn. Vai trò mà một chuyên gia sẽ hỗ trợ Bạn tìm ra con đường của
        riêng Bạn, giúp Bạn khai thác điểm mạnh và khả năng của riêng Bạn, để Bạn có thể xác định và sử dụng các nguồn
        lực xung quanh Bạn để lấp đầy bất kỳ khoảng trống nào;
      </p>
      <p className='p8'>7. Dịch vụ hỗ trợ trị liệu thông qua các trò chơi, các video hướng dẫn. Để xây dựng những tài
        liệu này, nhóm chuyên gia cũng dựa trên các nguyên tắc của liệu pháp dựa trên nhận thức (CBT) để giúp Bạn kiểm
        soát tình trạng tinh thần và cảm xúc của Bạn. Các chuyên gia của Vmood sẽ dựa trên các kỹ thuật dựa trên bằng
        chứng khác nhau để cung cấp hỗ trợ đồng cảm và không phán xét. Các chuyên gia sẽ lắng nghe những lo lắng của Bạn
        một cách thấu cảm, thúc đẩy sự tích cực và hỗ trợ Bạn trong việc thay đổi lối sống thành công, để Bạn có thể
        quản lý tình hình của mình tốt hơn và xây dựng khả năng phục hồi về mặt cảm xúc;
      </p>
      <p className='p8'>8. Ứng dụng Vmood cho phép Bạn kết nối và có các phiên trị liệu theo lịch trình dựa trên việc
        đặt lịch với chuyên gia của Vmood. Bạn hiểu và đồng ý rằng các Dịch vụ được cung cấp nhằm mục đích lắng nghe
        Bạn, hỗ trợ Bạn đạt được các mục tiêu đã xác định của Bạn và chỉ Bạn đến các công cụ Ứng dụng Vmood phù hợp;
      </p>
      <p className='p8'>9. Bạn hiểu và đồng ý rằng Dịch vụ chăm sóc sức khỏe tâm thần của Vmood hoặc Dịch vụ chuyên gia
        của Vmood không thay thế cho liệu pháp tâm lý mặt đối mặt và / hoặc không đưa ra chẩn đoán, tiên lượng, điều trị
        hoặc cách chữa bệnh / tình trạng / rối loạn, và / hoặc không cung cấp bất kỳ loại dịch vụ sức khỏe tâm thần nào
        do nhà nước quản lý tại quốc gia cư trú của Bạn;
      </p>
      <p className='p8'>11. Dịch vụ của Vmood không phải là một Dịch vụ cấp cứu hay khủng hoảng. Nếu bất cứ lúc nào Bạn
        gặp phải trường hợp khẩn cấp, liên quan đến y tế hoặc khác, vui lòng liên hệ ngay với những đơn vị liên hệ khẩn
        cấp tại khu vực địa phương của Bạn;
      </p>
      <p className='p8'>12. Thông qua ứng dụng Vmood sẽ chỉ định một chuyên gia cho Bạn khi Bạn đăng ký Dịch vụ hoặc khi
        bạn lên lịch buổi đầu tiên của mình; <span className='s2'>www.vmood.org</span>
      </p>
      <p className='p8'>13. Bạn cũng có thể chuyển đổi chuyên gia của mình bất kỳ lúc nào sau 04 buổi trị liệu đầu tiên
        của bạn bằng cách viết thư cho Vmood theo địa chỉ: vmood@phad.org ;
      </p>
      <p className='p8'>14. Các phiên được lên lịch theo lịch trình thuận tiện cho cả khách hàng và Chuyên gia trị liệu
        Vmood;
      </p>
      <p className='p8'>15. Bạn có trách nhiệm lên lịch một buổi học với chuyên gia Vmood được chỉ định của Bạn để thống
        nhất thời gian thực hiện;
      </p>
      <p className='p8'>16. Ngoài một phiên đã lên lịch, Bạn có thể gửi tin nhắn và trò chuyện với chuyên gia chăm sóc
        sức khỏe của Vmood và chuyên gia sẽ cố gắng trả lời trong vòng 24 giờ. Nhắn tin sẽ chỉ bằng trò chuyện dựa trên
        văn bản và chỉ thông qua Ứng dụng Vmood. Bạn đồng ý không thu thập chi tiết liên lạc hoặc bất kỳ thông tin cá
        nhân nào khác, hoặc liên hệ với Chuyên gia bên ngoài Ứng dụng Vmood;
      </p>
      <p className='p8'>17. Bạn có thể hủy hoặc lên lịch lại một buổi tư vấn đã lên lịch bất kỳ lúc nào trước 3 giờ để
        bắt đầu buổi học đã lên lịch. Không được phép hủy bỏ hoặc lên lịch lại trong vòng 3 giờ hoặc sau khi phiên bắt
        đầu;
      </p>
      <p className='p8'>18. Các cuộc trò chuyện riêng tư của bạn với một chuyên gia Vmood bất cứ lúc nào trong Ứng dụng
        Vmood. Bạn sẽ có thể truy cập các cuộc trò chuyện và tóm tắt phiên của mình với Chuyên gia ngay cả sau khi đăng
        ký của bạn kết thúc;
      </p>
      <p className='p8'>19. Chuyên gia trị liệu Vmood của Bạn có ý định cung cấp các Dịch vụ hỗ trợ đạt được các mục
        tiêu của Bạn. Dựa trên Thông tin Bạn cung cấp và các chi tiết cụ thể về tình trạng của Bạn, chuyên gia sẽ đưa ra
        các khuyến nghị cho Bạn. Người dùng, và chuyên gia là những đối tác trong quá trình hỗ trợ. Bạn có quyền đồng ý
        hoặc không đồng ý với các đề xuất của chuyên gia;
      </p>
      <p className='p8'>20. Bạn đồng ý với nghi thức nhắn tin riêng với chuyên gia Vmood của bạn hoặc và đăng tin nhắn
        theo phần Nội dung người dùng trong thỏa thuận này;
      </p>
      <p className='p8'>21. Ứng dụng Vmood đóng vai trò như một “đầu nối” để Bạn truy cập trực tuyến các nội dung chăm
        sóc sức khỏe của Vmood hoặc Chuyên gia Vmood. Bạn đồng ý rằng Bạn đang sử dụng Dịch vụ theo đánh giá tốt nhất
        của mình và chúng tôi sẽ đưa ra tuyên bố hoặc bảo đảm dựa trên các bằng chứng khoa học về (a) tính chính xác
        hoặc tính khả dụng của Ứng dụng Vmood, (b) sự sẵn lòng hoặc khả năng của Chuyên gia để đưa ra lời khuyên hoặc
        các liệu pháp tự luyện tập, (c) sự sẵn lòng hoặc khả năng của bất kỳ Huấn luyện viên chăm sóc sức khỏe Vmood
        hoặc Vmood Therapist để đưa ra lời khuyên, hoặc (d) liệu lời khuyên của chuyên gia sức khỏe Vmood hoặc Bác sĩ
        trị liệu của Vmood có phù hợp với nhu cầu của Bạn hoặc bất kỳ ai khác hay không;
      </p>
      <p className='p8'>22. Vmood thực hiện tham chiếu và kiểm tra lý lịch của tất cả Chuyên gia của Vmood. Bạn có thể
        xem tất cả hồ sơ về Chuyên gia trị liệu Vmood của Chúng tôi;
      </p>
      <p className='p8'>23. Bạn hiểu và đồng ý rằng, mặc dù Chuyên gia của Vmood có thể đã được truy cập thông qua Ứng
        dụng Vmood, nhưng <strong>chúng tôi</strong> không thể dự đoán hoặc đánh giá năng lực, hoặc sự phù hợp với nhu
        cầu của bạn, của chuyên gia. Bạn cũng thừa nhận và đồng ý rằng Bạn hoàn toàn chịu trách nhiệm về quyết định tiếp
        cận Chuyên gia của Vmood;
      </p>
      <p className='p8'>24. Phí thành viên hàng tháng cho Dịch vụ chăm sóc sức khỏe Vmood hoặc Dịch vụ chuyên gia Vmood
        sẽ được đề cập trên Ứng dụng Vmood và được chỉ định cho Bạn tại thời điểm mua hàng (“Phí”). Chúng tôi có thể tùy
        ý sửa đổi Phí. Mọi thay đổi đối với Phí sẽ chỉ có hiệu lực khi bạn chấp thuận thông qua Phần mềm Di động. Chúng
        tôi có quyền ngừng Dịch vụ khi Bạn không thanh toán Phí. Phí được thanh toán thông qua các cơ chế thanh toán
        khác do chúng tôi giới thiệu theo quyết định riêng của mình vào từng thời điểm, trước thời điểm Dịch vụ được
        cung cấp;
      </p>
      <p className='p8'>25. Vì tư vấn chuyên gia không phải là một phương pháp điều trị y tế, nên bảo hiểm thường sẽ
        không thanh toán chi phí;
      </p>
      <p className='p8'>26. Đăng ký của Bạn sẽ được tự động gia hạn cho đến khi Đăng ký của Bạn bị hủy. Bạn hoàn toàn có
        trách nhiệm hủy bỏ, có thể được thực hiện bất kỳ lúc nào từ Google Play và Apple iTunes;
      </p>
      <p className='p8'>27. Việc hủy đăng ký của bạn phụ thuộc vào phương thức thanh toán của Bạn. Đây là hai cách để
        hủy:
      </p>
      <p className='p8'>Google Play:</p>
      <p className='p8'>iTunes: https:</p>
      <p className='p8'>28. Phí mà Người dùng phải trả là một khoản phí cố định và do đó, Bạn có thể sử dụng Dịch vụ
        nâng cao sức khỏe của Vmood hoặc Dịch vụ chuyên gia của Vmood tùy thích, nhưng nếu được yêu cầu Bạn sẽ phải trả
        phí thành viên;
      </p>
      <p className='p8'>29. Bạn có trách nhiệm ghi lại ngày và giờ mà đăng ký sẽ hết hạn hoặc đến ngày gia hạn và hủy bỏ
        trước khi thanh toán của Bạn được xử lý. Các khoản thanh toán có thể tuân theo tỷ giá hối đoái hiện hành;
      </p>
      <p className='p8'>30. Ứng dụng Vmood có thể đưa ra các chương trình khuyến mãi trong ứng dụng theo thời gian như
        dùng thử miễn phí, giảm giá hoặc nâng cấp lên gói trong một khoảng thời gian giới hạn. Bạn hiểu và đồng ý rằng
        các chương trình khuyến mãi đó chỉ có thể có một lần và không thể gia hạn hoặc có thể chuyển nhượng cho nhiều
        Người dùng;
      </p>
      <p className='p8'>31. Ứng dụng Vmood có thể sẽ yêu cầu Bạn cung cấp một số Thông tin Nhận dạng Cá nhân (chẳng hạn
        như tên đầy đủ, email, số điện thoại, địa chỉ, giới tính và các thông tin nhận dạng khác bao gồm Thông tin nhạy
        cảm như Thông tin y tế của Bạn hoặc ý kiến tôn giáo). Bất kỳ Thông tin nào do Bạn chia sẻ sẽ được xử lý theo
        Chính sách Bảo mật;
      </p>
      <p className='p8'>32. Bạn có các quyền và những quyền này có thể được xem trong Chính sách Bảo mật. Nếu Bạn có bất
        kỳ câu hỏi, thắc mắc hoặc khiếu nại nào, vui lòng liên hệ với Chúng tôi theo địa chỉ <span className='s3'>vmood@phad.org;</span>
      </p>
      <p className='p8'>33. Chúng tôi sử dụng mục đích thống kê và nghiên cứu dữ liệu sau khi được mã hóa để cải thiện
        Sản phẩm và Dịch vụ của Chúng tôi và góp phần phát triển các phương pháp hay nhất lấy người dùng làm trung tâm
        cho việc nâng cao sức khỏe tâm thần trên toàn cầu. Vui lòng tham khảo Chính sách quyền riêng tư về cách chúng
        tôi xử lý dữ liệu của Bạn;
      </p>
      <p className='p8'>34. Chúng tôi có quyền sửa đổi hoặc ngừng, tạm thời hoặc vĩnh viễn, bất kỳ Dịch vụ nào trong
        hoặc trên Ứng dụng Vmood, có hoặc không có thông báo cho Người dùng. Người dùng đồng ý rằng chúng tôi sẽ không
        chịu trách nhiệm với Người dùng hoặc bất kỳ bên thứ ba nào về bất kỳ tổn thất hoặc thiệt hại hoặc thương tích
        nào có thể dẫn đến Người dùng hoặc bất kỳ bên thứ ba nào do việc ngừng hoặc gián đoạn Dịch vụ đó. Tuy nhiên, nếu
        Dịch vụ bị ngừng tạm thời hoặc ngừng cung cấp vĩnh viễn, chúng tôi sẽ bắt đầu hoàn lại Phí cho Người dùng đã
        thanh toán trên cơ sở xem xét bất kỳ phần nào chưa sử dụng của Dịch vụ. Khoản hoàn trả đó sẽ tuân theo chính
        sách hoàn tiền và các điều khoản và điều kiện của Vmood (tùy theo điều kiện nào áp dụng). Trong trường hợp như
        vậy, Bạn phải liên hệ với Chúng tôi để được cung cấp các yêu cầu như vậy. Bạn sẽ cần gửi yêu cầu email kèm theo
        lý do của Bạn tới <span className='s3'>vmood@phad.org</span> Chúng tôi sẽ giải quyết bất kỳ yêu cầu nào như vậy
        theo từng trường hợp;
      </p>
      <p className='p8'>35. Tất cả các cuộc trò chuyện và dữ liệu thu được trong quá trình sử dụng Dịch vụ là bí mật.
        Việc lưu trữ, sử dụng, bảo mật Thông tin được điều chỉnh bởi Chính sách Bảo mật;
      </p>
      <p className='p8'>36. Bạn đồng ý và cho phép Vmood thu thập và xử lý dữ liệu của Bạn theo các điều khoản trong
        Chính sách Bảo mật của chúng tôi;
      </p>
      <p className='p8'>37. DỊCH VỤ VMOOD HOẶC CHUYÊN GIA VMOOD CÓ THỂ TIẾT LỘ DỮ LIỆU CỦA BẠN NẾU VMOOD TIN TƯỞNG HỢP
        LÝ RẰNG VIỆC CÔNG BỐ LÀ CẦN THIẾT VÀ PHẢI PHÙ HỢP VỚI QUY TRÌNH CỦA PHÁP LUẬT (ĐÓ LÀ MỘT MỆNH LỆNH HỢP PHÁP),
        TÌM KIẾM PHÁP LÝ KHÁC YÊU CẦU BẤT KỲ SỰ CHO PHÉP CỦA CHÍNH PHỦ;
      </p>
      <p className='p8'>40. Bạn hiểu rằng việc sử dụng công nghệ không phải lúc nào cũng an toàn và Bạn chấp nhận rủi ro
        về công nghệ trong quá trình sử dụng thiết bị di động của Bạn. Bạn sẽ tuân thủ các phương pháp bảo mật trong quá
        trình sử dụng kỹ thuật số, bao gồm nhưng không giới hạn việc đặt mã PIN để bảo vệ quyền truy cập, không chia sẻ
        mã này với bất kỳ ai và không để điện thoại hoặc cuộc trò chuyện của bạn được mở khóa cho người khác xem. Bạn
        hiểu và đồng ý rằng việc sử dụng an toàn là trách nhiệm của Bạn, chúng tôi sẽ không chịu trách nhiệm trong bất
        kỳ trường hợp nào phát sinh do truy cập trái phép vào các cuộc trò chuyện của Bạn hoặc dữ liệu khác;
      </p>
      <p className='p8'>41. Chúng tôi và Bạn cùng công nhận rằng họ có thể tổ chức các cuộc thảo luận dựa trên dữ liệu
        và thông tin do Bạn cung cấp. Chúng tôi sẽ không tự nguyện thông báo dữ liệu của Bạn cho bên thứ ba. Bất kỳ việc
        sử dụng dữ liệu nào do Bạn tiết lộ cho chuyên gia sẽ tuân theo Chính sách quyền riêng tư;
      </p>
      <p className='p8'>42. Để tôn vinh và bảo vệ tài sản trí tuệ của Vmood, Bạn hoàn toàn đồng ý không tiết lộ hoặc
        truyền đạt bất kỳ Thông tin độc quyền nào về Vmood, tài liệu hoặc phương pháp cho bất kỳ người thứ ba nào. Các
        nghĩa vụ theo Điều khoản này vẫn còn hiệu lực sau khi Dịch vụ của Vmood hoặc mối quan hệ của Bạn với Vmood chấm
        dứt;
      </p>
      <p className='p6'><strong>Quy tắc dịch vụ</strong></p>
      <p className='p8'>Bạn đồng ý không tham gia vào bất kỳ hoạt động bị cấm nào nhằm vào Vmood.</p>
      <p className='p8'>Chúng tôi có thể thay đổi Dịch vụ mà không cần thông báo trước; ngừng cung cấp Dịch vụ hoặc các
        tính năng của Dịch vụ cho Bạn hoặc cho Người dùng nói chung; hoặc tạo giới hạn sử dụng cho Dịch vụ. Chúng tôi có
        thể chấm dứt vĩnh viễn hoặc tạm thời hoặc đình chỉ quyền truy cập của Bạn vào Dịch vụ mà không cần thông báo và
        chịu trách nhiệm pháp lý vì bất kỳ lý do gì, bao gồm nếu theo quyết định riêng của Chúng tôi, Bạn vi phạm bất kỳ
        điều khoản nào của Thỏa thuận hoặc không có lý do. Sau khi chấm dứt vì bất kỳ lý do nào hoặc không có lý do, Bạn
        tiếp tục bị ràng buộc bởi Thỏa thuận. Bạn chịu trách nhiệm về tất cả việc sử dụng dữ liệu di động do sử dụng
        Dịch vụ. Bạn hoàn toàn chịu trách nhiệm về các tương tác của Bạn với những Người dùng Vmood khác. Chúng tôi có
        quyền, nhưng không có nghĩa vụ giám sát các tranh chấp giữa Bạn và Người dùng khác. Vmood sẽ không có trách
        nhiệm pháp lý đối với các tương tác của Bạn với Người dùng khác, hoặc đối với bất kỳ hành động hoặc không hành
        động nào của Người dùng.
      </p>
      <p className='p10'><strong>NỘI DUNG NGƯỜI DÙNG </strong></p>
      <p className='p8'>Một số khu vực của Dịch vụ có thể, bây giờ hoặc sau này, cho phép Người dùng đăng nội dung như
        nội dung trò chuyện, Thông tin hồ sơ, ảnh, video, nhận xét, câu hỏi và nội dung hoặc Thông tin khác [bất kỳ tài
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        liệu nào như vậy mà Người dùng gửi, đăng, hiển thị hoặc bằng cách khác cung cấp trên Dịch vụ ("Nội dung Người
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        dùng")]. Chúng tôi tuyên bố không có quyền sở hữu đối với Nội dung Người dùng do Bạn tạo; Nội dung Người dùng
        Bạn tạo vẫn là Của Bạn. Tuy nhiên, bằng cách chia sẻ Nội dung Người dùng thông qua Dịch vụ, Bạn đồng ý cho phép
        người khác (theo cài đặt của bạn và Thỏa thuận này) xem, chỉnh sửa và / hoặc chia sẻ Nội dung Người dùng của
        Bạn. Vmood có quyền (nhưng không có nghĩa vụ) theo quyết định riêng của mình để xóa bất kỳ Nội dung Người dùng
        nào được chia sẻ qua Dịch vụ.
      </p>
      <p className='p8'>Bạn đồng ý không đăng hoặc truyền Nội dung Người dùng làm ảnh hưởng đến Vmood hoặc pháp luật cấm
        hoặc không <span className='s1'>cho phép</span>. <span className='s1'>Bạn </span>đồng ý rằng bất kỳ Nội dung
        Người dùng nào mà Bạn đã đăng, không và sẽ không vi phạm các quyền của bên thứ ba dưới bất kỳ hình thức nào, bao
        gồm nhưng không giới hạn bất kỳ Quyền sở hữu trí tuệ nào (như được định nghĩa bên dưới) hoặc quyền riêng tư.
        Vmood có quyền, nhưng không có nghĩa vụ, từ chối và / hoặc xóa bất kỳ Nội dung Người dùng nào mà Vmood cho rằng,
        theo quyết định riêng của mình, vi phạm các quy định này.
      </p>
      <p className='p10'><strong>CẤP PHÉP NỘI DUNG NGƯỜI DÙNG </strong></p>
      <p className='p8'>Bằng cách đăng bất kỳ Nội dung Người dùng nào trên Dịch vụ, để sử dụng liên quan đến Dịch vụ mà
        Bạn cấp phép rõ ràng, và Bạn tuyên bố và đảm bảo rằng Bạn có tất cả các quyền cần thiết để cấp, cho Vmood một
        quyền miễn phí bản quyền, có thể cấp phép lại, có thể chuyển nhượng, vĩnh viễn, không thể thu hồi, không - giấy
        phép độc quyền trên toàn thế giới để sử dụng, tái sản xuất, sửa đổi, xuất bản, liệt kê Thông tin liên quan đến,
        chỉnh sửa, dịch, phân phối, cung cấp, thực hiện công khai, hiển thị công khai và tạo các sản phẩm phái sinh của
        tất cả Nội dung Người dùng đó và Tên, giọng nói của Bạn và / hoặc giống như trong Nội dung Người dùng của Bạn,
        toàn bộ hoặc một phần, và dưới bất kỳ hình thức, phương tiện hoặc công nghệ nào, cho dù hiện đã được biết đến
        hay sau này được phát triển theo Chính sách Bảo mật.
      </p>
      <p className='p10'><strong>SỬ DỤNG NỘI DUNG NGƯỜI DÙNG</strong></p>
      <p className='p8'>Tất cả dữ liệu bao gồm Nội dung người dùng nếu có đều được ẩn danh và mã hóa trước khi sử dụng
        cho mục đích nghiên cứu. Nghiên cứu này xem xét các hoạt động khác nhau trên Phần mềm Di động tác động như thế
        nào đến hiệu quả của các biện pháp nhằm giúp khách hàng. Vmood có thể sử dụng Nội dung Người dùng và các dữ liệu
        khác được thu thập từ Người dùng theo Thỏa thuận cho mục đích nghiên cứu này. Nghiên cứu và kết quả là chìa khóa
        để giúp Người dùng thực hiện thay đổi và tiến bộ thực sự trong cuộc sống của họ và chúng tôi biết ơn sự hỗ trợ
        mà Bạn cung cấp cho sứ mệnh này bằng cách chia sẻ dữ liệu của Bạn.
      </p>
      <p className='p10'><strong>PHẦN MỀM DI ĐỘNG</strong></p>
      <p className='p8'>Chúng tôi cung cấp Phần mềm Di động có sẵn để truy cập Dịch vụ qua thiết bị di động. Để sử dụng
        Phần mềm Di động Bạn phải có thiết bị di động tương thích với Phần mềm Di động. Vmood không đảm bảo rằng Phần
        mềm Di động sẽ tương thích với thiết bị di động của Bạn. Bằng văn bản này, Vmood cấp cho Bạn giấy phép không độc
        quyền, không thể chuyển nhượng, có thể thu hồi để sử dụng bản sao mã đã biên dịch của Phần mềm Di động cho một
        Tài khoản Vmood chỉ do Bạn sở hữu hoặc cho thuê, cho mục đích sử dụng cá nhân của Bạn. Bạn đồng ý với việc nâng
        cấp tự động trên thiết bị di động của Bạn và đồng ý rằng Thỏa thuận này sẽ áp dụng cho tất cả các nâng cấp đó.
        Bất kỳ mã của bên thứ ba nào có thể được tích hợp trong Phần mềm Di động đều tuân theo nguồn mở hiện hành hoặc
        giấy phép bên thứ ba Thỏa thuận cấp phép người dùng cuối, nếu có, cho phép sử dụng mã đó. Việc cấp giấy phép nói
        trên không phải là việc bán Phần mềm Di động hoặc bất kỳ bản sao nào của phần mềm đó và Vmood hoặc các đối tác
        hoặc nhà cung cấp bên thứ ba của nó giữ lại mọi quyền, quyền sở hữu và lợi ích đối với Phần mềm Di động (và bất
        kỳ bản sao nào của phần mềm đó). Bất kỳ nỗ lực nào của Bạn để chuyển giao bất kỳ quyền, nghĩa vụ hoặc nghĩa vụ
        nào dưới đây, trừ khi được quy định rõ ràng trong Thỏa thuận này, đều vô hiệu. Vmood bảo lưu tất cả các quyền
        không được cấp rõ ràng theo Thỏa thuận này.
      </p>
      <p className='p10'><strong>QUYỀN SỞ HỮU CỦA CHÚNG TÔI </strong></p>
      <p className='p8'>Ngoại trừ Nội dung Người dùng của Bạn, Dịch vụ và tất cả các tài liệu trong đó hoặc được chuyển
        qua đó, bao gồm nhưng không giới hạn, phần mềm, hình ảnh, văn bản, đồ họa, hình minh họa, nhãn hiệu, nhãn hiệu
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        dịch vụ, bản quyền, ảnh, âm thanh, video, nhạc, và Nội dung Người dùng thuộc về Người dùng khác ("Nội dung của
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        Vmood"), và tất cả Quyền sở hữu trí tuệ liên quan đến nội dung đó, là tài sản độc quyền của Vmood và người cấp
        phép của Vmood (bao gồm cả Người dùng khác đăng Nội dung Người dùng lên Dịch vụ). Việc sử dụng Nội dung Vmood
        cho bất kỳ mục đích nào không được cho phép rõ ràng bởi Thỏa thuận này đều bị nghiêm cấm.
      </p>
      <p className='p8'>Bạn có thể chọn hoặc Chúng tôi có thể mời Bạn gửi nhận xét hoặc ý tưởng về Dịch vụ, bao gồm
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        nhưng không giới hạn về cách cải thiện Dịch vụ hoặc sản phẩm của Chúng tôi ("Ý tưởng"). Bằng cách gửi bất kỳ Ý
        tưởng nào, Bạn đồng ý rằng việc tiết lộ của Bạn là vô cớ, không được yêu cầu và không có giới hạn và sẽ không
        đặt Vmood dưới bất kỳ người ủy thác hoặc nghĩa vụ nào khác, và rằng Chúng tôi có thể tự do sử dụng Ý tưởng mà
        không phải trả thêm bất kỳ khoản bồi thường nào cho Bạn và / hoặc tiết lộ Ý tưởng trên cơ sở không bí mật hoặc
        đối với bất kỳ ai. Bạn cũng thừa nhận rằng, bằng việc chấp nhận đệ trình của Bạn, Vmood không từ bỏ bất kỳ quyền
        nào để sử dụng các ý tưởng tương tự hoặc có liên quan mà Vmood đã biết trước đây, hoặc được phát triển bởi nhân
        viên của nó, hoặc lấy từ các nguồn khác ngoài Bạn.
      </p>
      <p className='p10'><strong>LỜI KHUYÊN CHUYÊN NGHIỆP</strong></p>
      <p className='p8'>Dịch vụ cung cấp Thông tin về lĩnh vực sức khỏe tâm thần bao gồm các công cụ và kỹ thuật đề xuất
        (ví dụ: Yoga hoặc hoạt động trị liệu hoặc bài tập trị liệu), thì Thông tin đó chỉ dành cho mục đích thông tin và
        được hiểu là lời khuyên chuyên nghiệp. Nên cân nhắc thực hiện những hành động, hướng dẫn chính thức dựa trên các
        Thông tin có trong Dịch vụ Vmood. Bạn cũng có thể tìm kiếm lời khuyên chuyên môn độc lập từ một người được cấp
        phép và / hoặc đủ điều kiện trong lĩnh vực áp dụng trước khi sử dụng.
      </p>
      <p className='p10'><strong>RIÊNG TƯ</strong></p>
      <p className='p8'>Chúng tôi quan tâm sâu sắc đến quyền riêng tư của Người dùng của chúng tôi. Bạn hiểu rằng bằng
        cách sử dụng Dịch vụ, Bạn đồng ý với việc thu thập, sử dụng và tiết lộ dữ liệu Cá nhân của Bạn và dữ liệu tổng
        hợp như được quy định trong Chính sách Bảo mật của chúng tôi và để dữ liệu của Bạn được Chúng tôi thu thập, sử
        dụng, chuyển giao và xử lý theo quy định của Chúng tôi Chính sách bảo mật.
      </p>
      <p className='p10'><strong>BẢO VỆ</strong></p>
      <p className='p8'>Chúng tôi quan tâm sâu sắc đến tính toàn vẹn và bảo mật của dữ liệu Cá nhân của Bạn và duy trì
        các biện pháp bảo mật theo yêu cầu của luật hiện hành ở Việt Nam và trên toàn cầu để đảm bảo dữ liệu được phép
        sử dụng. Tuy nhiên, chúng tôi không thể đảm bảo rằng các bên thứ ba trái phép sẽ không bao giờ có thể đánh bại
        các biện pháp bảo mật của Chúng tôi hoặc sử dụng dữ liệu Cá nhân của Bạn cho các mục đích không chính đáng. Bạn
        xác nhận rằng Bạn tự chịu rủi ro khi cung cấp dữ liệu Cá nhân của mình.
      </p>
      <p className='p10'><strong>LIÊN KẾT CỦA BÊN THỨ BA </strong></p>
      <p className='p8'>Dịch vụ có thể chứa các liên kết hoặc nội dung khác liên quan đến các trang web, nhà quảng cáo,
        sản phẩm và / hoặc dịch vụ của bên thứ ba, các ưu đãi đặc biệt hoặc các sự kiện hoặc hoạt động khác được cung
        cấp bởi bên thứ ba không thuộc quyền sở hữu hoặc kiểm soát của Vmood. Nếu Bạn truy cập trang web của bên thứ ba
        từ Dịch vụ, Bạn tự chịu rủi ro và Bạn hiểu rằng Thỏa thuận này và Chính sách Bảo mật của Vmood không áp dụng cho
        việc Bạn sử dụng các trang web đó. Bạn miễn trừ cho Vmood khỏi bất kỳ và tất cả trách nhiệm pháp lý, trực tiếp
        hoặc gián tiếp, phát sinh từ việc Bạn sử dụng bất kỳ trang web, dịch vụ hoặc nội dung nào của bên thứ ba;
      </p>
      <p className='p10'><strong>GIÁM SÁT TRANG WEB, PHẦN MỀM DI ĐỘNG VÀ NỘI DUNG NGƯỜI DÙNG </strong></p>
      <p className='p8'>Chúng tôi có quyền và tự do giám sát nội dung trên Trang web và Phần mềm Di động mọi lúc bao gồm
        Thông tin được cung cấp trong Tài khoản của Bạn hoặc bất kỳ Nội dung Người dùng nào do Bạn đăng. Việc giám sát
        Trang web là quan trọng để xác định tính trung thực của Thông tin do Bạn cung cấp và mọi Người dùng vẫn tuân thủ
        các Điều khoản và Điều kiện được cung cấp ở đây. Theo Thỏa thuận, Chúng tôi cũng sẽ có quyền xóa bất kỳ nội dung
        bị phản đối nào, bao gồm cả Nội dung của Người dùng, trái với Thỏa thuận hoặc chia sẻ Thông tin đó với bất kỳ cơ
        quan chính phủ nào theo quy trình của luật pháp hiện hành ở Việt Nam. Bằng văn bản này, Vmood có quyền nhưng
        không có nghĩa vụ giám sát nội dung trên Trang web và Phần mềm Di động. Theo đó, Vmood sẽ không chịu trách nhiệm
        về bất kỳ khiếu nại, tổn thất, chi phí thiệt hại hoặc chi phí nào có thể phát sinh do Nội dung Người dùng do Bạn
        hoặc bất kỳ Người dùng nào khác xuất bản trên Trang web.
      </p>
      <p className='p10'><strong>THANH TOÁN</strong></p>
      <p className='p8'>[Ứng dụng Vmood có thể cung cấp liên kết để hỗ trợ từ dịch vụ, trong trường hợp đó, các điều
        khoản và Chính sách quyền riêng tư của họ sẽ được áp dụng.]
      </p>
      <p className='p8'>Dịch vụ được cung cấp miễn phí cho Người dùng hiện tại. Người dùng có thể chọn sử dụng các dịch
        vụ bổ sung có sẵn trên Phần mềm Di động (“Dịch vụ Cao cấp”) khi thanh toán phí đăng ký. Dịch vụ cao cấp sẽ được
        coi là một phần của Dịch vụ theo mục đích của Thỏa thuận. Chúng tôi có thể sửa đổi phí đăng ký thành viên cho
        các Dịch vụ cao cấp hoặc làm cho tất cả các Dịch vụ phải trả phí đăng ký nhất định theo quyết định riêng của
        chúng tôi. Người dùng sẽ được thông báo về khoản phí áp dụng phải trả cho Dịch vụ và Dịch vụ cao cấp trước khi
        Người dùng sử dụng dịch vụ đó.
      </p>
      <p className='p8'>Đăng ký sẽ tự động được gia hạn cho đến khi đăng ký bị hủy trước khi kết thúc giai đoạn hiện
        tại. Người dùng hoàn toàn có trách nhiệm hủy, có thể thực hiện trực tiếp bất kỳ lúc nào từ Vmood hoặc điện thoại
        thông báo đến số…….. CHÚNG TÔI KHÔNG CHẤP NHẬN CÁC ĐIỀU KIỆN HỦY ĐĂNG KÝ QUA EMAIL;
      </p>
      <p className='p8'>Người dùng có trách nhiệm lưu ý ngày và giờ đăng ký sẽ hết hạn hoặc đến ngày gia hạn và hủy
        trước khi thanh toán của Bạn được xử lý. Các khoản thanh toán có thể tuân theo tỷ giá hối đoái hiện hành.
      </p>
      <p className='p8'>Việc hủy đăng ký của bạn tùy thuộc vào phương thức thanh toán của Bạn. Đây là hai cách để hủy:
        ……..
      </p>
      <p className='p10'><strong>BỒI THƯỜNG</strong></p>
      <p className='p8'>Bạn đồng ý bảo vệ, bồi thường và giữ Vmood vô hại và chống lại tất cả các khiếu nại, thiệt hại,
        nghĩa vụ, tổn thất, trách nhiệm pháp lý và chi phí (bao gồm nhưng không giới hạn ở phí luật sư) phát sinh từ:
        (i) Việc bạn sử dụng và truy cập Dịch vụ, bao gồm bất kỳ dữ liệu hoặc nội dung nào do Bạn truyền hoặc nhận; (ii)
        Bạn vi phạm bất kỳ điều khoản nào của Thỏa thuận này, bao gồm nhưng không giới hạn Việc bạn vi phạm bất kỳ tuyên
        bố nào ở trên; (iii) Việc bạn vi phạm bất kỳ quyền nào của bên thứ ba, bao gồm nhưng không giới hạn bất kỳ quyền
        nào về quyền riêng tư hoặc Quyền sở hữu trí tuệ; (iv) Bạn vi phạm bất kỳ luật, quy tắc hoặc quy định hiện hành
        nào; (v) bất kỳ khiếu nại hoặc thiệt hại nào phát sinh do bất kỳ Nội dung Người dùng nào của Bạn hoặc bất kỳ nội
        dung nào được gửi qua tài khoản của Bạn; hoặc (vi) bất kỳ bên nào khác truy cập và sử dụng Dịch vụ bằng tên
        người dùng, mã PIN, mật khẩu duy nhất của Bạn hoặc mã bảo mật thích hợp khác.
      </p>
      <p className='p11'><strong>LUẬT ĐIỀU CHỈNH VÀ QUYỀN TÀI PHÁN</strong></p>
      <p className='p12'>Điều khoản và Điều kiện này và bất kỳ tranh chấp hoặc khiếu kiện nào phát sinh từ hoặc liên
        quan đến Điều khoản và Điều kiện này sẽ được giải thích và điều chỉnh theo pháp luật Việt Nam.
      </p>
      <p className='p12'>Bất kỳ tranh cãi, khiếu nại hoặc tranh chấp phát sinh liên quan đến việc sử dụng Nền tảng công
        nghệ Vmood của Khách hàng sẽ được giải quyết trước tiên bằng hình thức thương lượng trên tinh thần thiện chí.
        Trường hợp các bên không đạt được thỏa thuận về việc giải quyết tranh chấp thì tranh chấp đó sẽ được giải quyết
        bằng trọng tài tại Trung tâm Trọng tài Quốc tế Việt Nam (VIAC) bên cạnh Phòng Thương mại và Công nghiệp Việt Nam
        theo Quy tắc tố trụng trọng tài của VIAC, số lượng trọng tài viên là một, được chỉ định theo quy tắc của VIAC và
        ngôn ngữ trọng tài là tiếng Việt.
      </p>
      <p className='p13'><strong>THỦ TỤC THÔNG BÁO VÀ CÁC THAY ĐỔI ĐỚI VỚI THỎA THUẬN.</strong></p>
      <p className='p8'>Vmood có thể cung cấp các thông báo, cho dù các thông báo đó là do luật pháp yêu cầu hoặc dành
        cho mục đích tiếp thị hoặc kinh doanh khác, cho Bạn qua email, thông báo bằng văn bản hoặc bản in, hoặc thông
        qua việc đăng thông báo đó trên trang web của Chúng tôi, như được xác định bởi Vmood. Vmood có quyền xác định
        hình thức và phương tiện cung cấp thông báo cho Người dùng, với điều kiện là Bạn có thể chọn không tham gia một
        số phương thức thông báo như được mô tả trong Thỏa thuận này. Vmood không chịu trách nhiệm về bất kỳ bộ lọc tự
        động nào Bạn hoặc nhà cung cấp mạng của Bạn có thể áp dụng cho các thông báo qua email mà chúng tôi gửi đến địa
        chỉ email mà bạn cung cấp cho chúng tôi. Vmood có thể, theo quyết định riêng của mình, sửa đổi hoặc cập nhật
        Thỏa thuận này theo thời gian, và vì vậy Bạn nên xem lại trang này theo định kỳ. Khi Chúng tôi thay đổi Thỏa
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        thuận theo cách quan trọng, Chúng tôi sẽ cập nhật ngày 'sửa đổi lần cuối' ở đầu trang này. Việc bạn tiếp tục sử
        dụng Dịch vụ sau bất kỳ thay đổi nào như vậy cấu thành việc Bạn chấp nhận các Điều khoản và Điều kiện mới. Nếu
        Bạn không đồng ý với bất kỳ điều khoản nào trong số này hoặc bất kỳ Điều khoản và Điều kiện nào trong tương lai,
        không sử dụng hoặc không tiếp tục truy cập Dịch vụ.
      </p>
      <p className='p10'><strong>TOÀN BỘ THỎA THUẬN/TÍNH HIỆU LỰC TỪNG PHẦN.</strong></p>
      <p className='p8'>Thỏa thuận và Điều khoản này, cùng với bất kỳ sửa đổi, chính sách và bất kỳ thỏa thuận bổ sung
        nào mà Bạn có thể ký kết với Vmood liên quan đến Dịch vụ, sẽ cấu thành toàn bộ thỏa thuận giữa Bạn và Vmood liên
        quan đến Dịch vụ. Nếu bất kỳ điều khoản nào của Thỏa thuận này bị tòa án có thẩm quyền coi là vô hiệu, thì sự vô
        hiệu của điều khoản đó sẽ không ảnh hưởng đến hiệu lực của các điều khoản còn lại của Thỏa thuận này, sẽ vẫn có
        hiệu lực đầy đủ.
      </p>
      <p className='p14'><strong>GÓP Ý, KHIẾU NẠI VÀ LIÊN HỆ</strong></p>
      <p className='p12'>Chúng tôi khuyến khích mọi ý kiến đóng góp của Khách hàng để cải thiện Nền tảng công nghệ Vmood
        và dịch vụ của chúng tôi. Mọi góp ý, khiếu nại (nếu có) vui lòng gửi cho chúng tôi theo một trong các hình thức
        sau:
      </p>
      <p className='p12'>Qua tổng đài:</p>
      <p className='p12'>Qua hộp thư điện tử (email): <span className='s3'>vmood@phad.org</span></p>
      <p className='p12'>Trực tiếp tại văn phòng Công ty: Tầng 14, tòa nhà Icon4, số 1-3 Cầu Giấy, Đống Đa, Hà Nội hoặc
        gửi thư điện tử tới.
      </p>
      <p className='p10'><strong>=====================================</strong></p>
      <p className='p15'><strong>Viện Dân số, Sức khỏe và Phát triển (PHAD)</strong></p>
      <p className='p8'><strong>Trụ sở chính</strong> : Số 18, ngõ 132, Hoa Bằng, Cầu Giấy, Hà Nội<br />
        <strong>Văn phòng điều hành/ địa chỉ thư tín</strong>: Tầng 14, tòa nhà Icon4, số 1-3 Cầu Giấy, Đống Đa, Hà Nội<br /><strong>Điện thoại</strong>: (+84) 2473 000 988
      </p>
    </div>
  );
}

export default Vietnamese;