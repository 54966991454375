const clearAuth = () => {
  localStorage.removeItem("auth");
};

const clearAuthentic = () => {
  localStorage.removeItem("authentic");
};

const clearRememberPassword = () => {
  localStorage.removeItem("rememberPassword");
};

export {
  clearAuthentic,
  clearAuth,
  clearRememberPassword
};