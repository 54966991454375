import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './header.css';
import {Link, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';
import {toast} from 'react-toastify';
import logo from '../../assets/images/logo-header.svg';
import Language from '../../features/Language';
import Profile from '../Profile';
import {Logout, UpdateInfo} from '../../features/Login/action';
import GetLanguageConfigCode from '../../repositories/state/get';
import notifyAPI from "../../api/notify";
import informationAPI from "../../api/informationAPI";
import TimeZoneApi from "../../api/timeZone";
import {getTimeZoneName} from "../../repositories/localStorage/get";
import {SocketContext} from "../../contexts/SockerContext";

function Header(props) {
    const {isPhq9} = props;
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth.auth);
    const language = useSelector(state => state.language.lang);
    const isAuthentic = useSelector(state => state.auth.authentic);

    const [listNotify, setListNotify] = useState();
    const [countNotify, setCountNotify] = useState(0);
    const {socket} = useContext(SocketContext);

    function logout() {
        const online = window.navigator.onLine;
        if (online) {
            const action = Logout(true);
            dispatch(action);
            socket.disconnect();
            history.push('/login');
        } else {
            window.location.reload(true);
        }
    }

    const handleLogout = () => {
        const action = Logout(true);
        dispatch(action);
        history.push('/login');
    };

    useEffect(() => {
        if (auth && isAuthentic) {
            const callProfile = async () => {
                const response = await informationAPI.getInformation(auth.authentication_token);
                const {status} = response;
                if (status === 200 && response.data.data) {
                    if (JSON.stringify(auth) !== JSON.stringify(response.data.data)) {
                        const action = UpdateInfo(response.data.data);
                        dispatch(action);
                    }
                }
            }
            callProfile();
        }
    }, []);

    const callNotify = async (params) => {
        let response;
        if (params) {
            response = await notifyAPI.getAllNotify(auth.authentication_token, params);
        } else {
            response = await notifyAPI.getAllNotify(auth.authentication_token, {language_id: GetLanguageConfigCode(language)});
        }
        if (response.status === 200 && response.data.data) {
            response = await notifyAPI.getAllNotify(auth.authentication_token, {language_id: GetLanguageConfigCode(language)});
            if (response.status === 200 && response.data.data) {
                const {data} = response.data;
                if (Array.isArray(data)) {
                    const countData = data.reduce((sum, index) => {
                        if (index.status === 0) {
                            sum += 1;
                        }
                        return sum;
                    }, 0);
                    setListNotify(data);
                    setCountNotify(countData);
                } else {
                    setCountNotify(0)
                }
            } else if (response.status === 412) {
                setTimeout(1000);
                setTimeout(() => {
                    toast.error(t('notify.access_token_false'), {toastId: "access_token_false"});
                }, 1000)
                handleLogout()
            }
        } else if (response.status === 412) {
            setTimeout(() => {
                toast.error(t('notify.access_token_false'), {toastId: "access_token_false"});
            }, 1000)
            handleLogout()
        } else if (response.status === 307) {
            setTimeout(() => {
                window.location.href = "/home";
            }, 300)
        }
    };

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (auth.authentication_token && isAuthentic) {
            callNotify();
            const notify = setInterval(() => {
                callNotify();
            }, 60000);
            return () => {
                clearInterval(notify);
            }
        }
    }, [language]);

    const clickNotification = (id) => {
        callNotify({
            language_id: GetLanguageConfigCode(language),
            status: 1,
            id
        });
    };

    useEffect(() => {
        if (auth.authentication_token && isAuthentic) {
            const pushTimeZone = async () => {
                const response = await TimeZoneApi.updateTimeZone({time_zone: getTimeZoneName()}, auth.authentication_token);
                const {status} = response
                if (status === 412) {
                    handleLogout();
                    setTimeout(() => {
                        toast.error(t('notify.access_token_false'),
                            {
                                toastId: "access_token_false"
                            });
                    }, 1000)
                }
            }
            pushTimeZone();
        }
    }, [])

    return (
        <header>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-4 col-12'>
                        <div className='header-left'>
                            <div className='logo'>
                                <Link to='/home'>
                                    <img src={logo} alt='logo'/>
                                </Link>
                                <h2 className='text-left'>Vmood<br/>{t('language.slogan')}</h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-8 col-12'>
                        <div className='header-right d-flex'>
                            <Language/>
                            {(isAuthentic && !isPhq9) &&
                            <div className='centered'>
                                <Dropdown className='wrap-notify'>
                                    <Dropdown.Toggle id='dropdown-basic' className='button-notify' variant={false}>
                                        <i className='fa fa-bell icon-grey m-0'>
                                            {countNotify !== 0 &&
                                            <p className='number-notify'>{countNotify}</p>}
                                        </i>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-menu-arrow'>
                                        <div className='content-list-notify'>
                                            <span className='title'>{t('notify.title')}</span>
                                            {listNotify && listNotify.map((value, index) => (
                                                <Dropdown.Item
                                                    key={index} className={`child-notify ${value.status === 0 && 'new-notify'}`}
                                                    onClick={() => clickNotification(value.id)}
                                                >
                                                    <span><i className='far fa-bell icon'/></span>
                                                    <div className='text-time'>
                                                        <div className='text'>{value.message}</div>
                                                        <div className='notify-time'>{value.date}</div>
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Profile logoutLogic={logout} username={auth.username} avatar={auth.avatar_url}/>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

Header.propTypes = {
    isPhq9: PropTypes.bool,
};

Header.defaultProps = {
    isPhq9: false
};

export default Header;
