const ACCOUNT_TYPE_EN = {
    1: 'Volunteer',
    2: 'Caregiver',
    3: 'Social worker',
    4: 'Coach',
    5: 'Psychiatrist',
    6: 'Psychologist'
};

const LEVEL_EN = {
    0: 'Student',
    1: 'Bachelor',
    2: 'Master',
    3: 'Deputy Doctor',
    4: 'Doctor',
    5: 'Associate Professor',
    6: 'Professor',
    7: 'Other'
};

const ACCOUNT_TYPE_VN = {
    1: 'Tình nguyện viên',
    2: 'Người chăm sóc',
    3: 'Nhân viên xã hội',
    4: 'Huấn luyện viên',
    5: 'Bác sĩ tâm thần',
    6: 'Nhà tâm lý học'
};

const LEVEL_VN = {
    0: 'Sinh viên',
    1: 'Cử nhân',
    2: 'Thạc sĩ',
    3: 'Phó Tiến sĩ',
    4: 'Bác sĩ',
    5: 'Phó giáo sư',
    6: 'Giáo sư',
    7: 'Khác'
};

const ACCOUNT_TYPE_ESP = {
    1: 'El voluntario',
    2: 'Cuidador',
    3: 'Asistente social',
    4: 'Entrenador',
    5: 'Psiquiatra',
    6: 'Psicólogo',
};

const LEVEL_ESP = {
    0: 'Estudiante',
    1: 'Licenciatura',
    2: 'Maestro',
    3: 'Médico adjunto',
    4: 'Doctor',
    5: 'Profesor asociado',
    6: 'Profesor',
    7: 'Otro'
};
const REASON_SWITCH_VN = {
    1: 'Tình trạng bệnh diễn biến xấu hơn',
    2: 'Bệnh nhân đã khỏi bệnh',
    3: 'Chuyên gia bận việc, không thể tiếp tục tư vấn',
    4: 'Bệnh nhân tự chấm dứt tư vấn'
};
const REASON_SWITCH_EN = {
    1: 'The condition worsened',
    2: 'The client was cured',
    3: 'Experts are busy, can not continue to advise',
    4: 'The client self-terminated counseling'
};
const REASON_SWITCH_ESP = {
    1: 'La condición empeoró',
    2: 'El paciente se curó',
    3: 'Los expertos están ocupados, no pueden seguir asesorando',
    4: 'Auto-cese del asesoramiento al paciente'
}
export {
    ACCOUNT_TYPE_EN,
    LEVEL_EN,
    ACCOUNT_TYPE_VN,
    LEVEL_VN,
    ACCOUNT_TYPE_ESP,
    LEVEL_ESP,
    REASON_SWITCH_VN,
    REASON_SWITCH_EN,
    REASON_SWITCH_ESP
}
